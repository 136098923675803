import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { PrimaryButton, GoBackButton } from "../../../components/Buttons";
import CheckboxInput from "../../../components/CheckboxInput";
import PasswordInput from "../../../components/TextInput";
import TemplateLogin from "../../../components/TemplateLogin";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import Card from "../../../components/Card";

import { useAuth } from "../../../hooks";

import Hospitalar from "../../../images/labOrange.svg";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Digite a senha")
    
    .min(6, "A senha deve ter no mínimo 8 caracteres"),
  email: yup
    .string()
    .email("Digite um e-mail válido")
    .required("Digite o e-mail"),
});

// const breakpoints = {
//   base: "0em", // 0px
//   sm: "30em", // ~480px. em is a relative unit and is dependant on the font-size.
//   md: "48em", // ~768px
//   lg: "62em", // ~992px
//   xl: "80em", // ~1280px
//   "2xl": "96em", // ~1536px
// };

// const theme = extendTheme({ breakpoints });

export default function HospitalarLogin() {
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const navigate = useNavigate();

  async function submit(values) {
    try {
      setLoading(true);

      const validLogin = await signIn(values, 1);

      if (validLogin.status === 400) {
        navigate("/login");
      }

      if (validLogin.status !== 200) {
        return;
      }
      navigate("/gerenciamento-senne");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),

  });
  return (
    <>
      <TemplateLogin image="">
        {loading && <Loading />}
        <Content className="extend-bottom">
          <Card>
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              gap="37px"
              mb="71px"
            >
              <img src={Hospitalar} alt="" />
              <Text fontSize={{base: "24px", md: "40px", lg: "56px"}} fontWeight="bold">
                Administrador Senne
              </Text>
              
            </Flex>
            <form onSubmit={handleSubmit(submit)}  >
              <TextInput
                isError={errors.email}
                labelDescription="Login com seu e-mail cadastrado"
                InputPlaceholder="Email"
                register={{ ...register("email") }}
                name="email"
                type="email"
                id="email"
                onChange={(e) => setValue("email", e.target.value)}
              />
              <PasswordInput
                isError={errors.password}
                labelDescription="Senha"
                InputPlaceholder="Senha"
                register={{ ...register("password") }}
                name="password"
                type="password"
                id="password"
                onChange={(e) => setValue("password", e.target.value)}
                password
              />

              <Flex alignItems="center" justifyContent="space-between">
                <CheckboxInput labelDescription="Lembrar Senha"></CheckboxInput>
                <Link to="/nova-senha" target="_blank">
                  <Text
                    color="var( --orange-senne-secundary)"
                    textDecoration="underline"
                  >
                    Esqueci a Senha
                  </Text>
                </Link>
              </Flex>
              <PrimaryButton 
                type="submit"
              >
                Entrar
              </PrimaryButton>
            </form>
            <Flex width="100%" justifyContent="center">
              <Link to="/login">
                <GoBackButton />
              </Link>
            </Flex>
          </Card>
        </Content>
      </TemplateLogin>
    </>
  );
}
const Content = styled.div`
  padding: 72px 80px;

  h1 {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
    margin: 24px 0 0;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 414px) {
    .extend-bottom{
      margin-bottom: 100px;
    }
    padding: 15px;
    h1 {
      font-size: 1.5rem;
    }
    div {
      margin: 12px 0 20px 0;
    }
    input {
      margin-bottom: 10px;
    }
  }
`;
