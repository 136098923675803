import api from "../../services/api";
import { IActiveHospital } from "./types";

export function setActiveHospitalStorage(hospital: IActiveHospital | undefined) {
    if (!hospital) {
        localStorage.removeItem('h');

        // legacy localStorage
        localStorage.removeItem('hospital');
        localStorage.removeItem('hospitalName');
        localStorage.removeItem('permissions');
        return;
    }

    localStorage.setItem('h', JSON.stringify(hospital));

    // legacy localStorage
    hospital.uuid ? localStorage.setItem('hospital', hospital.uuid) : localStorage.removeItem('hospital');
    hospital.name ? localStorage.setItem('hospitalName', hospital.name) : localStorage.removeItem('hospitalName');
    hospital.permissions ? localStorage.setItem("permissions", hospital.permissions.join(',')) : localStorage.removeItem('permissions');
}

export function getActiveHospitalStorage(): IActiveHospital | undefined {
    const json = localStorage.getItem('h');

    if (!json) {
        return undefined;
    }

    return JSON.parse(json) ?? undefined;
}

export const getLastHospitalSelected = async () => (await api.get<{
    id: string;
    uuid: string;
    name: string;
    permissions?: number[];
}>(`/user/hospital`)).data;