import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar";
import InternalHeader from "../../components/InternalHeader";

import { BreadCrumbs } from "../../components/Breadcrumb2";
import { Flex, Box, Text, Tooltip } from "@chakra-ui/react";
import { Pagination } from "../../components/Pagination";
import avatarGroup from "../../images/avatarGroup.svg";

import { useGroups, useAuth } from "../../hooks";

import { CancelButton, PrimaryResultsButton } from "../../components/Buttons";

const GroupManager = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { getGroups } = useGroups();

  const [groupList, setGroupList] = useState();
  const [userGroupList, setUserGroupList] = useState([1, 2]);
  const itemsPerPage = 12;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  async function group_List_Senne() {
    const params = {
      page: currentPage ? currentPage + 1 : 1,
      per_page: itemsPerPage,
    };

    try {
      const results = await getGroups(params);
      setGroupList(results?.Grupos?.data);
      setPageCount(results?.Grupos?.last_page);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    group_List_Senne()
  }, [user])

  function verificate(group_list) {
    let unique_groups_id = [
      ...new Set(user?.hospitals?.map((item) => item.grupo_id)),
    ];
    setUserGroupList(unique_groups_id);

    let grupos = [];
    for (let i = 0; i < group_list?.length; i++) {
      if (unique_groups_id.includes(group_list[i]?.id)) {
        grupos.push(group_list[i]);
      }
    }
    setGroupList(grupos);
  }

  useEffect(() => {
    if (user) {
      group_List_Senne();
    }
  }, [currentPage]);

  return (
    <Flex width="100%" backgroundColor="var(--cinza-bg)">
      <Box
        p={{ base: "24px 24px 22px 24px", md: "24px 88px 22px 24px" }}
        w="calc(100% - 165px)"
      >
        <BreadCrumbs title="Gerenciamentos de Grupos" />

        <Box
          m="16px 88px 0 0"
          borderRadius="8px"
          backgroundColor="var(--cinza-bg)"
          flexDirection="row"
          width="100%"
          maxW="100%"
        >
          <Box
            p="20px 21px"
            backgroundColor="white"
            borderRadius="10px"
            border="1px solid var(--cinza-card)"
          >
            <Flex
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
              w="100%"
              gap="20px"
              flexDirection={{ base: "column", lg: "row" }}
            >
              {/* <Flex
                w="100%"
                h="34px"
                p="0 2px "
                border="1px solid var(--cinza-card)"
                color="#8F8F8F"
                borderRadius="4px"
                alignContent="center"
                alignItems="center"
                gap="10px"
                backgroundColor="#fff"
              >
                <img src={Search} alt="" />
                <Text as="strong" w="100%" fontSize="13px">
                  Pesquisar hospital :
                </Text>
                <Input
                  height="30px"
                  fontSize="13px"
                  padding="0"
                  _focus={{
                    border: "none",
                  }}
                  border="none"
                  placeholder="Digite aqui"
                  _placeholder={{ fontSize: "13px" }}
                />
              </Flex> */}
              <Flex w={{ base: "100%", md: "60%" }} h="30px">
                {/* <PrimaryResultsButton
                  onClick={() => navigate("/gerenciamento-grupos/nova-equipe")}
                >
                  <Flex justifyContent="space-between" gap="10px">
                    <img src={iconAdd} alt="" />

                    <Text w="100%" fontSize="12px">
                      Adicionar nova equipe
                    </Text>
                  </Flex>
                </PrimaryResultsButton> */}
              </Flex>
            </Flex>
            <Flex
              w="100%"
              justifyContent="space-around"
              // mt="28px"
              p="15px"
              // borderTop="1px solid var(--orange-senne)"
              gap="63.3px"
              wrap="wrap"
            >
              {/* uma quadro de grupo */}
              {groupList?.map((item, index) => (
                <Flex
                  key={index}
                  pt="10px"
                  flexDirection="column"
                  alignContent="center"
                  alignItems="center"
                  gap="5px"
                  h="100%"
                  border="1px solid var(--cinza-card)"
                  borderRadius="9px"
                  wrap="wrap"
                  mb="17px"
                  p="17px"
                  w="200px"
                  maxW="200px"
                >
                  <Flex
                    w="78px"
                    h="78px"
                    borderRadius="100%"
                    overflow="hidden"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid var(--cinza-card)"
                  >
                    <img src={item?.image ?? avatarGroup} alt="" />
                  </Flex>
                  <Tooltip
                    hasArrow
                    label={item?.name}
                    bg="gray.300"
                    color="black"
                  >
                    <Flex
                      w="100%"
                      h="50px"
                      maxH="50px"
                      overflowY="hidden"
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Text maxH="40px" textAlign="center">
                        {item?.name}
                      </Text>
                    </Flex>
                  </Tooltip>
                  <Box w="24px" h="24px">
                    <img src={avatarGroup} alt="" />
                  </Box>
                  <Box w="150px" h="24px">
                    <PrimaryResultsButton
                      onClick={() =>
                        navigate(
                          `/gerenciamento-senne/gerenciamento-grupos/${item?.id}`,
                        )
                      }
                    >
                      <Text fontSize="10px"> Ver Hospitais do grupo</Text>
                    </PrimaryResultsButton>
                  </Box>
                  <Box w="105px" h="16px">
                    <CancelButton
                      onClick={() =>
                        navigate(
                          `/gerenciamento-senne/gerenciamento-grupos/${item?.id}/editar`,
                        )
                      }
                    >
                      <Text fontSize="10px">Editar Grupo</Text>
                    </CancelButton>
                  </Box>
                </Flex>
              ))}
            </Flex>
            <Flex mt="20px" p="0 63.3px" justifyContent="end">
              <Pagination
                pageCount={pageCount}
                changePage={setCurrentPage}
                pageSelected={currentPage}
              />
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default GroupManager;
