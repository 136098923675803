import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../../hooks";
import { getReasonList } from "./utils";

import Select from "../../../../components/Select";

const FilterReason = () => {
    const auth = useAuth() as { user: { id: number } };
    const getReasonsRequestRef = useRef<AbortController | null>(null);

    const { data, isLoading } = useQuery({
        queryKey: ['reason_list', { user_id: auth.user?.id }],
        queryFn: async () => {
            getReasonsRequestRef.current?.abort();
            getReasonsRequestRef.current = new AbortController();

            return await getReasonList(getReasonsRequestRef.current.signal);
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 6 * 60 * 60 * 1000
    });

    return (
        <Select
            clearButton
            label='Motivo'
            name='reason_ids'
            theme='legacy'
            isLoading={isLoading}
            multiple
            options={data?.reasons.map(reason => ({ value: reason.id, label: reason.reason })) ?? [{ value: '', label: 'Nenhuma Opção Disponível' }]}
        />
    );
}

export default FilterReason;