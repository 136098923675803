import { createPortal } from 'react-dom';
import { IOption, ISelect, NormalizeOption } from './types';
import { forwardRef, MouseEvent as ReactMouseEvent, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import TimesIcon from '../../assets/icons/TimesIcon';
import Fuse from 'fuse.js';

import './style.scss';

interface IOptionPartial extends IOption {
    hidden?: boolean;
}

const Select = forwardRef(<Multiple extends true | undefined, Label extends string | undefined>({
    options,
    multiple,
    defaultValue,
    value,
    forceMobile,
    isLoading,
    clearButton,
    closeOnSelect,
    name,
    onChange,
    label,
    placeholder,
    javascriptSearch,
    searchOnChange,
    disabled,
    required,
    theme
}: ISelect<Multiple, Label>, ref: Ref<HTMLDivElement>) => {
    const placeholderRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<IOption[]>(!defaultValue ? [] : Array.isArray(defaultValue) ? defaultValue : [defaultValue]);
    const [selectOptions, setSelectOptions] = useState<IOptionPartial[]>(options);
    const [search, setSearch] = useState('');
    const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

    theme = theme ? theme : 'modern';

    useEffect(() => setSelectOptions(options), [options]);
    useEffect(() => { disabled && setIsOpen(false); }, [disabled]);

    useEffect(() => {
        if (value) {
            const initialSelected = Array.isArray(value)
                ? options.filter(option => value.some(processedOption => processedOption.value === option.value))
                : options.find(option => option.value === value.value);

            setSelectedOptions(Array.isArray(initialSelected) ? initialSelected : initialSelected ? [initialSelected] : []);
        }

        if (value === null) {
            setSelectOptions([]);
        }
    }, [value, options]);

    useEffect(() => {
        const handleAsideClickOutside = (e: MouseEvent) => {
            if (
                !placeholderRef.current?.contains(e.target as Node) &&
                !optionsRef.current?.contains(e.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleAsideClickOutside);
        return () => {
            window.removeEventListener('click', handleAsideClickOutside);
        }
    }, []);

    const getOptionLabelByFirstValue = useCallback(() => {
        const label = selectedOptions.at(-1)?.label;

        if (label) {
            return label;
        }

        const optionByValue = options.find(options => options.value === selectedOptions.at(-1)?.value);

        if (optionByValue?.label) {
            return optionByValue.label;
        }

        return optionByValue?.value;
    }, [selectedOptions, options]);

    const handleFuseSearch = useCallback(() => {
        if (search && javascriptSearch && !searchOnChange) {
            const fuse = new Fuse(selectOptions, { keys: ['label'], threshold: .6 });
            const searchResultValues = fuse.search(search).map(result => result.item).map(item => item.value);
            setSelectOptions(prev => [...prev].map(option => ({ ...option, hidden: !searchResultValues.includes(option.value) })));

            return;
        }

        setSelectOptions(prev => [...prev].map(option => ({ ...option, hidden: false })));
    }, [search, selectOptions, javascriptSearch, searchOnChange]);

    useEffect(() => handleFuseSearch(), [search, javascriptSearch, searchOnChange]);

    const handleToggleOptionSelect = (option: IOption) => {
        let updatedOptions: IOption[] = [];

        if (!multiple) {
            updatedOptions = selectedOptions.includes(option) ? [] : [option];
        }

        if (multiple) {
            updatedOptions = selectedOptions.map(prevOption => prevOption.value).includes(option.value) ?
                selectedOptions.filter((selectedOption) => selectedOption.value !== option.value) :
                [...selectedOptions, option];
        }

        setSelectedOptions(updatedOptions);

        closeOnSelect && setIsOpen(false);
        onChange && onChange((multiple ? updatedOptions : updatedOptions[0]) as NormalizeOption<Multiple>);
    }

    const handleClearSelections = (e: ReactMouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        setSelectedOptions([]);
        onChange && onChange((multiple ? [] : undefined) as NormalizeOption<Multiple>);
    }

    const selectedOptionsValues = selectedOptions.map(option => option.value);

    useEffect(() => {
        const updatePosition = () => {
            if (isOpen && placeholderRef.current) {
                const rect = placeholderRef.current.getBoundingClientRect();

                setPosition(prev => ({
                    top: rect.bottom + window.scrollY,
                    left: rect.left + window.scrollX,
                    width: prev.width
                }));
            }
        };

        if (isOpen) {
            updatePosition();
            window.addEventListener("scroll", updatePosition, true);
        }

        return () => {
            window.removeEventListener("scroll", updatePosition, true);
        };
    }, [isOpen, placeholderRef]);

    useEffect(() => {
        const updatePosition = () => {
            if (isOpen && placeholderRef.current) {
                const placeholderWidth = placeholderRef.current.offsetWidth;
                setPosition(prev => ({ ...prev, width: placeholderWidth }));
            }
        };

        if (isOpen) {
            updatePosition();
            window.addEventListener("resize", updatePosition);
        }

        return () => {
            window.removeEventListener("resize", updatePosition);
        };
    }, [isOpen, placeholderRef]);

    return (
        <div ref={ref} className={classNames(`will_select --${theme}`, { '--is_loading': isLoading, '--disabled': disabled, '--has_value': selectedOptions.length > 0 })}>
            {label && <label>{label}</label>}
            <div className='select_input' onClick={() => setIsOpen(prev => !prev)} ref={placeholderRef}>
                <div className={classNames('placeholder', { '--default_placeholder': selectedOptions.length === 0 })}>
                    {getOptionLabelByFirstValue() ?? placeholder ?? 'Selecione'}
                </div>
                <div
                    className='multiple_counter'
                    content={multiple && selectedOptions.length > 1 ? selectedOptions.length.toString() : undefined}
                />
                {!disabled && !isLoading && clearButton && <button
                    onClick={handleClearSelections}
                    className='--clear'
                    type='button'
                    children={<TimesIcon />}
                />}
                {required && !disabled ? <input className='required' required onChange={() => { }} value={selectedOptions.length > 0 ? 'has' : ''} /> : ''}
            </div>
            {createPortal(
                <div
                    className={classNames(`select_options --${theme}`, { '--force_mobile': forceMobile })}
                >
                    <div className='overlay' ref={overlayRef} onClick={e => e.target === overlayRef.current && setIsOpen(false)}>
                        <ul
                            className={classNames('options', { '--is_open': isOpen })}
                            style={window.innerWidth > 720 ? { top: `${position.top}px`, left: `${position.left}px`, width: position.width } : {}}
                            ref={optionsRef}
                        >
                            {(javascriptSearch || searchOnChange) &&
                                <li className='--search'>
                                    <input
                                        type='text'
                                        value={search}
                                        onChange={(e) => {
                                            searchOnChange && searchOnChange(e.target.value);
                                            setSearch(e.target.value);
                                        }}
                                        placeholder='Buscar'
                                    />
                                </li>
                            }
                            {selectOptions.map((option, index) =>
                                <li
                                    key={index}
                                    className={classNames({
                                        '--is_selected': selectedOptionsValues.includes(option.value),
                                        '--hidden': option.hidden
                                    })}
                                    onClick={() => handleToggleOptionSelect(option)}
                                    children={option.label ?? option.value}
                                />
                            )}
                        </ul>
                    </div>
                </div>,
                document.body
            )}
            {name && selectedOptionsValues.map((optionValue, index) => <input type='hidden' key={index} value={optionValue} name={multiple ? `${name}[${index}]` : name} />)}
        </div >
    );
});

export default Select;