import Select from "../../../../components/Select";

import { useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { searchDoctor } from "./utils";

const FilterDoctor = () => {
    const searchDoctorRequestRef = useRef<AbortController | null>(null);

    const [search, setSearch] = useState('');

    const { data, isLoading } = useQuery({
        queryKey: ['doctor_list', { search }],
        queryFn: async () => {
            searchDoctorRequestRef.current?.abort();
            searchDoctorRequestRef.current = new AbortController();

            return searchDoctor(search, searchDoctorRequestRef.current.signal);
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 6 * 60 * 60 * 1000
    });

    return (
        <Select
            clearButton
            label='Médico'
            name='cds_medico'
            multiple
            theme='legacy'
            isLoading={isLoading}
            options={data?.doctors.map(doctor => ({ value: doctor.id, label: doctor.medico_nome })) ?? [{ value: '', label: 'Nenhuma Opção Disponível' }]}
            searchOnChange={setSearch}
        />
    );
}

export default FilterDoctor;