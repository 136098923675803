import React from "react";
import {
  ButtonStyles,
  Primary,
  Secondary,
  Green,
  Back,
  Outline,
  Register,
  PrimaryResults,
  PrimaryTable,
  Cancel,
  OrangeSenne,
  Loggin,
  AzulSenne,
  Gray,
} from "./style";

import BackIcon from "../../images/back.svg";

function PrimaryButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <Primary {...props}>{children}</Primary>
    </>
  );
}
function AzulSenneButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <AzulSenne {...props}>{children}</AzulSenne>
    </>
  );
}

function OrangeSenneButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <OrangeSenne {...props}>{children}</OrangeSenne>
    </>
  );
}

function PrimaryResultsButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <PrimaryResults {...props}>{children}</PrimaryResults>
    </>
  );
}

function GrayButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <Gray {...props}>{children}</Gray>
    </>
  );
}

function CancelButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <Cancel {...props}>{children}</Cancel>
    </>
  );
}

function TableButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <PrimaryTable {...props}>{children}</PrimaryTable>
    </>
  );
}

function RegisterButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <Register {...props}>{children}</Register>
    </>
  );
}

function SecondaryButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <Secondary {...props}>{children}</Secondary>
    </>
  );
}

function GreenButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <Green {...props}>{children}</Green>
    </>
  );
}

function GoBackButton({ click }) {
  return (
    <>
      <ButtonStyles />
      <Back onClick={click}>
        <img src={BackIcon} alt="" />
        Voltar
      </Back>
    </>
  );
}

function OutlineButton({ children }) {
  return (
    <>
      <ButtonStyles />
      <Outline>{children}</Outline>
    </>
  );
}

function LogginButton({ children, ...props }) {
  return (
    <>
      <ButtonStyles />
      <Loggin {...props}>
        {/* <div> */}
          {children}
        {/* </div> */}
      </Loggin>
    </>
  );
}

export {
  PrimaryButton,
  SecondaryButton,
  AzulSenneButton,
  GreenButton,
  GoBackButton,
  OutlineButton,
  RegisterButton,
  PrimaryResultsButton,
  TableButton,
  CancelButton,
  OrangeSenneButton,
  LogginButton,
  GrayButton,
};
