import styled from "styled-components";
import HeaderLogo from "./HeaderLogo";
import BackgroundRegister from "../../src/images/reset-passwordBG.png";

export default function TemplateLogin({ children, colorClass }) {
  return (
    <>
      <HeaderLogo />
      <BlockPrimary>
        <div className={`content ${colorClass}`}>{children}</div>
      </BlockPrimary>
    </>
  );
}

const BlockPrimary = styled.div`
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 103px);
  max-height: 100vh;

  width: 100%;
  background: url(${BackgroundRegister});
  background-repeat: no-repeat;

  background-size: cover;

  .content {
    display: flex;
    justify-content: center;
    align-content: center;

    &.green {
      background-color: #b9bd5c;
    }
    &.color-senne {
      background-color: #b9bd5c;
    }
  }
  @media screen and (max-width: 800px) {
    display: block;
    max-height: 100vh;
    .image {
      max-height: 300px;
      overflow: hidden;
    }
  }
`;
