import { useState, useEffect, useCallback, useMemo } from "react";
import { registerLocale } from "react-datepicker";
import { useAuth, useExams, useHospitals, useUsers } from "../../hooks";
import {
	extendTheme,
	useDisclosure,
} from "@chakra-ui/react";
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";
import { ROLE_OPERATION, ROLE_PATIENT } from "../../types/role";
import { PERMISSION_ADMINISTRATOR, PERMISSION_RESULT, PERMISSION_SCHEDULING } from "../../types/permissions";

import ptBR from "date-fns/locale/pt-BR";
import GridAuthorization from "../../components/GridAuthorization";
import GridAppointment from "../../components/GridAppointment";
import ModalNotification from "../../components/ModalNotification";
import GridAttendance from "../../components/GridAttendance";
import Tabs from "../../components/Tabs";

import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import { Modal } from "../../components/Modal";
import styled from "styled-components";

const breakpoints = {
	sm: "320px",
	md: "768px",
	lg: "960px",
	xl: "1200px",
	"2xl": "1536px",
};

const StyledContainer = styled.div`
	/* width: 550px; */
	max-width: 550px;
	h1{
		font-size: 1.75rem;
		color: #990507;
		text-align: center;
		margin-bottom: 1.5rem;
	}

	p {
		text-align: center;
		margin: 1.5rem 0; /* Espaçamento em cima e embaixo */
		line-height: 1.6; /* Aumenta o espaçamento entre as linhas */
		font-size: 1.25rem; /* Aumenta o tamanho da fonte */
	}
`

extendTheme({ breakpoints });

function Dashboard() {
	registerLocale("ptBR", ptBR);

	const { listAppointment, listAuthorization } = useExams();
	const { user } = useAuth();
	const { getHospitalNotification } = useHospitals();
	const { onClose } = useDisclosure();
	const activeHospital = useActiveHospital();

	const [isModalNotificationOpen, setIsModalNotificationOpen] = useState(true)
	const [isModalAlertOpen, setIsModalAlertOpen] = useState(true)

	const [showAppointment, setShowAppointment] = useState(null);
	const [showAuthorization, setShowAuthorization] = useState(null);
	const [showDashboard, setShowDashboard] = useState(null);

	const [uuidUser, setUUIDUser] = useState(activeHospital.uuid);
	const [permissions, setPermissions] = useState();

	const itemsPerPage = 3;
	const [pageCountAppointment, setPageCountAppointment] = useState(0);
	const [pageCountAuthorization, setPageCountAuthorization] = useState(0);
	const [currentPageAppointment, setCurrentPageAppointment] = useState(0);
	const [currentPageAuthorization, setCurrentPageAuthorization] = useState(0);

	const [notificationList, setNotificationList] = useState([]);

	console.log(notificationList)
	const handleChildClick = () => {
		getAppointmentList(uuidUser);
	};

	useEffect(() => {
		getAppointmentList(uuidUser);
		getAuthorizationList();
	}, [currentPageAppointment, currentPageAuthorization]);

	useEffect(() => {
		getAppointmentList()
		getAuthorizationList()
	}, [user])

	const closeModal = () => {
		setIsModalAlertOpen(false)
	}

	async function listHospitalNotification(uuidChanged) {
		if (!uuidChanged) {
			return
		}

		setNotificationList(await getHospitalNotification(uuidChanged));
	}

	async function getAuthorizationList() {
		if (!uuidUser) {
			return;
		}
		if (!user) {
			return;
		}
		try {
			const params = {
				pageNo: currentPageAuthorization ? currentPageAuthorization + 1 : 1,
				pageSize: itemsPerPage,
				Order: "DESC",
			};
			const response = await listAuthorization(uuidUser, user?.role_id, params);

			setShowAuthorization(response?.Authorization?.[0]?.authorization_list);
			setPageCountAuthorization(response?.Authorization[0]?.total_pages);
		} catch (error) {
			console.error(error);
		}
	}

	async function getAppointmentList(uuidUser) {
		if (!uuidUser) {
			return;
		}
		if (!user) {
			return;
		}

		try {
			const params = {
				pageNo: currentPageAppointment ? currentPageAppointment + 1 : 1,
				pageSize: itemsPerPage,
				Order: "DESC",
			};

			const response = await listAppointment(uuidUser, user?.role_id, params);

			setShowAppointment(response?.Appointment?.[0]?.appointment_list);
			setPageCountAppointment(response?.Appointment[0]?.total_pages);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		setUUIDUser(activeHospital.uuid);
		setPermissions(activeHospital.permissions);
		getAppointmentList(activeHospital.uuid);
		listHospitalNotification(activeHospital.uuid);
	}, [activeHospital.uuid]);

	useEffect(() => {
		getAuthorizationList();

		if (uuidUser !== undefined) {
			setShowDashboard(true);
		}
	}, [uuidUser]);

	const scheduleMemo = useMemo(() =>
		<GridAppointment
			showAppointment={showAppointment}
			pageCountAppointment={pageCountAppointment}
			currentPageAppointment={currentPageAppointment}
			setCurrentPageAppointment={setCurrentPageAppointment}
			onChildClick={handleChildClick}
		/>,
		[showAppointment, pageCountAppointment, currentPageAppointment, setCurrentPageAppointment, handleChildClick]
	);

	const authorizationMemo = useMemo(() =>
		<GridAuthorization
			showAuthorization={showAuthorization}
			pageCountAuthorization={pageCountAuthorization}
			currentPageAuthorization={currentPageAuthorization}
			setCurrentPageAuthorization={setCurrentPageAuthorization}
		/>,
		[showAuthorization, pageCountAuthorization, currentPageAuthorization, setCurrentPageAuthorization]
	);

	const resultMemo = useMemo(() => <GridAttendance uuidSelected={uuidUser} pageSize={10} />, [uuidUser]);

	const tabsMemo = useMemo(() => {
		const tabs = [];

		(
			user?.role_id === ROLE_OPERATION ||
			permissions?.find(permission => permission === PERMISSION_ADMINISTRATOR) ||
			permissions?.find(permission => permission === PERMISSION_SCHEDULING)
		) && tabs.push({ title: 'Agendamento', content: scheduleMemo });

		tabs.push({ title: 'Autorização', content: authorizationMemo });

		(
			user?.role_id === ROLE_OPERATION ||
			user?.role_id === ROLE_PATIENT ||
			permissions?.find(permission => permission === PERMISSION_ADMINISTRATOR) ||
			permissions?.find(permission => permission === PERMISSION_RESULT)
		) && tabs.push({ title: 'Resultado', content: resultMemo });

		return tabs;
	}, [user?.role_id, permissions, scheduleMemo, authorizationMemo, resultMemo]);

	return (
		<>
			{showDashboard ?
				<div className="dashboard_container" children={<Tabs tabs={tabsMemo} />} /> :
				<div className="dashboard_no_hospital_message">
					<span onClick={() => activeHospital.setChangeActiveHospitalIsOpen(true)}>Selecione o Hospital para visualizar o dashboard</span>
				</div>
			}

			{(!isModalAlertOpen) && (user?.role_id === 2 && notificationList.length > 0 && uuidUser) && (
				<ModalNotification
					notificationList={notificationList}
					onClose={onClose}
					setIsModalNotificationOpen={setIsModalNotificationOpen}
					isOpen={isModalNotificationOpen}
				/>
			)}

			{
				(user?.role_id === 2 && isModalAlertOpen) && (
					<Modal
						closeModal={closeModal}
					>
						<StyledContainer>
							<h1>AVISO IMPORTANTE</h1>
							<p>
								A partir de <strong>07/04/2025</strong>, estamos implementando novas políticas de agendamento para garantir
								maior segurança e agilidade nos nossos processos.<br /><br />
								Agora, será necessário apresentar o pedido médico assinado e carimbado no momento do agendamento e informar uma senha de internação hospitalar quando solicitado.<br /><br />
								Informamos que a divulgação do comunicado foi iniciada no dia 10/02.
							</p>
						</StyledContainer>
					</Modal>
				)
			}
		</>
	);
}

export default Dashboard;
