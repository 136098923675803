import { useAuth, useExams } from "../../hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { Pagination } from "../Pagination/index.js";
import { IExams } from "./types";
import { toast } from "react-toastify";
import { registerLocale } from "react-datepicker";

import ptBR from "date-fns/locale/pt-BR";
import PaletaExames from "../paletaExames.jsx";
import Result from "./Result";
import Filter from "./Filter";
import Form from "../Form";

import './style.scss';

interface IParams {
  abnormal: '0' | '1';
  end_date: string;
  name?: string;
  start_date: string;
  uuidHopistal?: string;
}

const GridAttendance = ({
  pageSize,
  uuidSelected
}: {
  pageSize: number,
  uuidSelected?: string
}) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const { user } = useAuth() as { user: { role_id: number; hash: string; } };
  const { newAttendanceRange } = useExams() as { newAttendanceRange: any };

  registerLocale("ptBR", ptBR);

  const [currentPage, setCurrentPage] = useState(0);

  const [exams, setExams] = useState<{ result: IExams[], total: number }>({ result: [], total: 0 });
  const [filterIsLoading, setFilterIsLoading] = useState(false);

  useEffect(() => { formRef.current?.requestSubmit() }, [currentPage, uuidSelected]);

  const result = useMemo(() => <Result results={exams.result} uuidSelected={uuidSelected} />, [uuidSelected, exams.result]);
  const filter = useMemo(() => <Filter filterIsLoading={filterIsLoading} />, [filterIsLoading]);

  async function getFilteredExamsList(params: IParams) {
    if (!uuidSelected) {
      if (user?.hash === null) {
        return;
      }

      uuidSelected = user?.hash;
    }

    try {
      const [startDay, startMonth, startYear] = params.start_date.split('/');
      const [endDay, endMonth, endYear] = params.end_date.split('/');

      const convertedStartDate = `${startYear}-${startMonth}-${startDay}`;
      const convertedEndDate = `${endYear}-${endMonth}-${endDay}`;

      setFilterIsLoading(true);

      const response = await newAttendanceRange(
        uuidSelected,
        convertedStartDate,
        convertedEndDate,
        {
          pageNo: currentPage ? currentPage + 1 : 1,
          pageSize: pageSize,
          Order: 'DESC',
          NomePaciente: params.name,
          filterAbnormal: params.abnormal,
          uuidHopistal: user?.role_id === 1 ? uuidSelected : undefined
        }
      );

      setFilterIsLoading(false);

      setExams({
        result: response?.Atendimentos?.[0]?.lista_atendimento ?? [],
        total: response?.Atendimentos?.[0]?.total_pages ?? 0
      });
    } catch (error) {
      toast.error('Falha ao realizar a busca');
      console.error(error);
    }
  }

  return (
    <>
      <div className="grid_attendance">
        <div className="title">
          Resultado de Exames
        </div>
        <Form
          refObject={formRef}
          onFinish={getFilteredExamsList}
          className="filter"
          children={filter}
        />
        <div className="results">{result}</div>
        <div className="attendance_footer">
          <div children={<PaletaExames />} />
          <div
            children={
              <Pagination
                changePage={setCurrentPage}
                pageCount={exams.total}
                pageSelected={currentPage}
              />
            }
          />
        </div>
      </div>
    </>
  );
}

export default GridAttendance;