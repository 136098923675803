import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { ISelect } from './types';
import { getSelectOptions } from './utils';

import Select from '../../../../components/Select';

const FieldsSelect = ({
    name,
    placeholder,
    required,
    showLabel,
    route
}: {
    name: string;
    placeholder: string;
    required?: boolean;
    showLabel?: boolean;
    route?: string;
}) => {
    const selectRef = useRef<AbortController | null>(null);

    const { data, isLoading } = useQuery<ISelect[]>({
        queryKey: ['select', { route }],
        queryFn: () => {
            selectRef.current?.abort();
            selectRef.current = new AbortController();

            return getSelectOptions(route as string);
        },
		staleTime: 6 * 60 * 60 * 1000,
        enabled: !!route
    });

    return (
        <Select
            label={!showLabel ? undefined : placeholder}
            placeholder={showLabel ? undefined : placeholder}
            options={data ?? []}
            name={name}
            closeOnSelect
            javascriptSearch
            isLoading={isLoading}
            clearButton
            required={required}
            theme='legacy'
        />
    );
}

export default FieldsSelect;