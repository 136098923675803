function FlowSideBarIcon() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 17 17"
      >
        <path
          className="icon"
        //   d="M1 11.0017C1 10.7365 1.10536 10.4821 1.29289 10.2946C1.48043 10.1071 1.73478 10.0017 2 10.0017H4C4.26522 10.0017 4.51957 10.1071 4.70711 10.2946C4.89464 10.4821 5 10.7365 5 11.0017V14.0017C5 14.2669 4.89464 14.5213 4.70711 14.7088C4.51957 14.8964 4.26522 15.0017 4 15.0017H2C1.73478 15.0017 1.48043 14.8964 1.29289 14.7088C1.10536 14.5213 1 14.2669 1 14.0017V11.0017ZM6 7.00171C6 6.73649 6.10536 6.48214 6.29289 6.2946C6.48043 6.10707 6.73478 6.00171 7 6.00171H9C9.26522 6.00171 9.51957 6.10707 9.70711 6.2946C9.89464 6.48214 10 6.73649 10 7.00171V14.0017C10 14.2669 9.89464 14.5213 9.70711 14.7088C9.51957 14.8964 9.26522 15.0017 9 15.0017H7C6.73478 15.0017 6.48043 14.8964 6.29289 14.7088C6.10536 14.5213 6 14.2669 6 14.0017V7.00171ZM11 2.00171C11 1.73649 11.1054 1.48214 11.2929 1.2946C11.4804 1.10707 11.7348 1.00171 12 1.00171H14C14.2652 1.00171 14.5196 1.10707 14.7071 1.2946C14.8946 1.48214 15 1.73649 15 2.00171V14.0017C15 14.2669 14.8946 14.5213 14.7071 14.7088C14.5196 14.8964 14.2652 15.0017 14 15.0017H12C11.7348 15.0017 11.4804 14.8964 11.2929 14.7088C11.1054 14.5213 11 14.2669 11 14.0017V2.00171Z" 
          d="M6 1V4H1V1H6ZM1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 4C0 4.26522 0.105357 4.51957 0.292893 4.70711C0.48043 4.89464 0.734784 5 1 5H6C6.26522 5 6.51957 4.89464 6.70711 4.70711C6.89464 4.51957 7 4.26522 7 4V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0L1 0ZM15 12V15H10V12H15ZM10 11C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12V15C9 15.2652 9.10536 15.5196 9.29289 15.7071C9.48043 15.8946 9.73478 16 10 16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15V12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11H10ZM6 8V15H1V8H6ZM1 7C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H6C6.26522 16 6.51957 15.8946 6.70711 15.7071C6.89464 15.5196 7 15.2652 7 15V8C7 7.73478 6.89464 7.48043 6.70711 7.29289C6.51957 7.10536 6.26522 7 6 7H1ZM15 1V8H10V1H15ZM10 0C9.73478 0 9.48043 0.105357 9.29289 0.292893C9.10536 0.48043 9 0.734784 9 1V8C9 8.26522 9.10536 8.51957 9.29289 8.70711C9.48043 8.89464 9.73478 9 10 9H15C15.2652 9 15.5196 8.89464 15.7071 8.70711C15.8946 8.51957 16 8.26522 16 8V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0L10 0Z"

        //   d="M4 11.0017C4 10.7365 4.10536 10.4821 4.29289 10.2946C4.48043 10.1071 4.73478 10.0017 5 10.0017C5.26522 10.0017 5.51957 10.1071 5.70711 10.2946C5.89464 10.4821 6 10.7365 6 11.0017V12.0017C6 12.2669 5.89464 12.5213 5.70711 12.7088C5.51957 12.8964 5.26522 13.0017 5 13.0017C4.73478 13.0017 4.48043 12.8964 4.29289 12.7088C4.10536 12.5213 4 12.2669 4 12.0017V11.0017ZM10 7.00171C10 6.73649 10.1054 6.48214 10.2929 6.2946C10.4804 6.10707 10.7348 6.00171 11 6.00171C11.2652 6.00171 11.5196 6.10707 11.7071 6.2946C11.8946 6.48214 12 6.73649 12 7.00171V12.0017C12 12.2669 11.8946 12.5213 11.7071 12.7088C11.5196 12.8964 11.2652 13.0017 11 13.0017C10.7348 13.0017 10.4804 12.8964 10.2929 12.7088C10.1054 12.5213 10 12.2669 10 12.0017V7.00171ZM7 9.00171C7 8.73649 7.10536 8.48214 7.29289 8.2946C7.48043 8.10707 7.73478 8.00171 8 8.00171C8.26522 8.00171 8.51957 8.10707 8.70711 8.2946C8.89464 8.48214 9 8.73649 9 9.00171V12.0017C9 12.2669 8.89464 12.5213 8.70711 12.7088C8.51957 12.8964 8.26522 13.0017 8 13.0017C7.73478 13.0017 7.48043 12.8964 7.29289 12.7088C7.10536 12.5213 7 12.2669 7 12.0017V9.00171Z" 

        ></path>
      </svg>
      );
  }
  
  export default FlowSideBarIcon;
  