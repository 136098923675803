import { forwardRef, Ref } from "react";
import { IButton } from "./types";

import classNames from "classnames";

import './style.scss';

const Button = forwardRef(({
    content,
    label,
    color,
    type,
    isLoading,
    style,
    onClick
}: IButton, ref: Ref<HTMLButtonElement>) => {
    return (
        <div className='ctm_button'>
            {label !== undefined && <label>{label}</label>}
            <button
                children={!isLoading ? content : ''}
                className={classNames(`--${color}`, { '--is_loading': isLoading })}
                ref={ref}
                type={type ? type : 'button'}
                style={style}
                onClick={onClick}
            />
        </div>
    );
});

export default Button;