import classNames from "classnames";
import Badge from "../../../components/Badge";
import FloatBox from "../../../components/FloatBox";

import { OCCURRENCE_STATUSES_COLOR, OCCURRENCE_STATUSES_CONTENT } from "../../../constants/occurrence";
import { IOccurrence, IOccurrenceWithLogs } from "../../../types/occurrence";

import './style.scss';

const OccurrenceDetails = ({
    occurrence,
    occurrenceIsLoading,
    onClick
}: {
    occurrence: IOccurrence | IOccurrenceWithLogs;
    occurrenceIsLoading?: boolean;
    onClick?: (occurrenceId: number) => void;
}) => {

    return (
        <div
            className={classNames('occurrence_details', { '--is_loading': occurrenceIsLoading, '--on_click': !!onClick })}
            onClick={() => onClick && onClick(occurrence.occurrence_id)}
        >
            <header>
                <div className='--responsible'>{occurrence.responsible}</div>
                <div className='--status'>
                    <Badge
                        color={OCCURRENCE_STATUSES_COLOR[occurrence.status_id]}
                        children={OCCURRENCE_STATUSES_CONTENT[occurrence.status_id]}
                    />
                </div>
            </header>
            <main>
                <div className="content">{occurrence.occurrence}</div>
                <div className="reason">{occurrence.reason}</div>
                {('logs' in occurrence && occurrence.logs) &&
                    <div className="logs">
                        {occurrence.logs.logs.map(log => {
                            return (
                                <FloatBox
                                    title={OCCURRENCE_STATUSES_CONTENT[log.json.STATUS_ID]}
                                    theme='badge'
                                    closeOnClickOutside
                                    color={OCCURRENCE_STATUSES_COLOR[log.json.STATUS_ID]}
                                >
                                    <main className="logs__main">
                                        <div className="content">{log.json.OCCURRENCE}</div>
                                    </main>
                                    <footer className="logs__footer">
                                        <div className='--reason'>{log.json.REASON}</div>
                                        <div className='--created_at'>{new Date(log.json.CREATED_AT).toLocaleString()}</div>
                                    </footer>
                                </FloatBox>
                            )
                        })}
                    </div>
                }
            </main>
            <footer>
                <div className='--doctor'>{occurrence.medico_name ?? occurrence.hospital_name}</div>
                <div className='--created_at'>{new Date(occurrence.created_at).toLocaleString()}</div>
            </footer>
        </div>
    );
}

export default OccurrenceDetails;