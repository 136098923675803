import { forwardRef, Ref } from "react";
import { ITextarea } from "./types";

import './style.scss';

const Textarea = forwardRef((
    {
        theme,
        label,
        placeholder,
        name,
        onChange,
        required
    }: ITextarea,
    ref: Ref<HTMLTextAreaElement>
) => {
    theme = theme ? theme : 'modern';

    return (
        <div className={`ctm_textarea --${theme}`}>
            {label !== undefined && <label>{label}</label>}
            <textarea
                ref={ref}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                required={required}
                autoComplete="off"
            />
        </div>
    );
});

export default Textarea;