import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TemplateLogin from "../../components/TemplateLogin";

import { LogginButton, GoBackButton } from "../../components/Buttons";
import { Flex } from "@chakra-ui/react";
import Person from "../../images/person.svg";
import SenneIcon from "../../images/logo_w.svg";
import PersonDoctor from "../../images/personDoctor.svg";


import Lab from "../../images/lab.svg";
const chaves = Object.keys(localStorage);
const chavesParaManter = ['Auth_senne', 'User_id_senne'];

chaves.forEach(chave => {
  if (!chavesParaManter.includes(chave)) {
  }
});

function Login() {
  return (
    <>
      <TemplateLogin>
        <Content>
          <img src="../../images/CentraExames.svg" alt="" />
          <p>
            Centro de referência em coleta e análise de líquor com experiência e
            inovação para trazer o melhor resultado.
          </p>

          <Flex
            flexDirection="column"
            backgroundColor="white"
            borderRadius="19px"
            width="100%"
            p="30px 60px"
          >
            <Link to="/login/paciente">
              <LogginButton>
                <img src={Person} alt="" />
                <span>Paciente ou Responsável</span>
              </LogginButton>
            </Link>

            <Link to="/login/hospital">
              <LogginButton>
                <img src={Lab} alt="" />
                <span>Corpo Clínico e Colab. dos Hospitais</span>
              </LogginButton>
            </Link>
            <Link to="/login/medico">
              <LogginButton>
                <img src={PersonDoctor} alt="" />
                <span>Médico Prescritores</span>
              </LogginButton>
            </Link>

            <Link to="/login/administrador">
              <LogginButton>
                <img src={SenneIcon} alt="" />
                <span>Operação Senne Liquor</span>
              </LogginButton>
            </Link>
            <Flex w="100%" mt="10px" justifyContent="center">
              <Link to="/">
                <GoBackButton />
              </Link>
            </Flex>
          </Flex>
        </Content>
      </TemplateLogin>
    </>
  );
}

const Content = styled.div`
  padding: 2em 7.5em;
  color: #fff;

  .teste{
    display: flex;
    flex-wrap: wrap;
    background-color: red;
    width: 100%;
  }
  p {
    line-height: 23px;
    font-size: 1rem;
    margin: 16px 0;
  }
  a {
    text-decoration: none;
  }

  @media (max-width: 1200px) {
    padding: 15px;
    h1 {
      font-size: 1.2rem;
      margin: 0 0 14px;
    }
    p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 800px) {
    padding-bottom: 150px;
  }
`;

export default Login;
