import { createGlobalStyle } from "styled-components";
import background from "../images/banner-home.jpg";

const HomeStyle = createGlobalStyle`
  .HomeApp {
    background: url(${background})  center center no-repeat;
    overflow-y: auto;
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
  }

  @media screen and (max-width: 600px) {
    .HomeApp {
      .container {
        flex-direction: column-reverse;
      }
      max-height: 100vh;
      display: block;
    }
  }
`;

export default HomeStyle;
