import { useParams } from "react-router-dom";
import { ReactNode, useRef, useState } from "react";
import { IInputType, IReportParams } from "./types";
import { useReportModal } from "../../contexts/ReportModal/useReportModal";
import { useQuery } from "@tanstack/react-query";
import { generateReport, getReportParams } from "./utils";
import { getReportDetails } from "../../contexts/ReportModal/UpdateReportModal/utils";
import { useAuth } from "../../hooks";
import { Role, ROLE_OPERATION } from "../../types/role";

import FieldInput from "./Fields/Input";
import FieldsSelect from "./Fields/Select";
import Form from "../../components/Form";
import PencilSquareIcon from "../../assets/icons/PencilSquareIcon";
import ReportSkeleton from "./ReportSkeleton";
import PdfViewer from "../../components/PdfViewer";
import classNames from "classnames";

import './style.scss';

export const Report = () => {
	const getReportDetailsRequestRef = useRef<AbortController | null>(null);
	const reportParamsRef = useRef<AbortController | null>(null);
	const reportGenerateRef = useRef<AbortController | null>(null);

	const params = useParams();
	const reportModal = useReportModal();
	const auth = useAuth() as { user: { id: number; role_id: Role; hash: string; name: string; hospitals: { name: string; }[]; }, logout: () => void };

	const reportId = parseInt(params.report_id as string);

	const [submitedParams, setSubmitedParams] = useState<any>(undefined);

	const { data: reportDetails, isLoading: reportDetailsIsLoading } = useQuery({
		queryKey: ['report_details', { report_id: reportId }],
		queryFn: () => {
			getReportDetailsRequestRef.current?.abort();
			getReportDetailsRequestRef.current = new AbortController();

			return getReportDetails(reportId as number, getReportDetailsRequestRef.current.signal);
		},
		refetchOnWindowFocus: false,
		retry: false,
		staleTime: 6 * 60 * 60 * 1000
	});

	const { data, isLoading } = useQuery({
		queryKey: ['report_params', { reportId }],
		queryFn: () => {
			reportParamsRef.current?.abort();
			reportParamsRef.current = new AbortController();

			return getReportParams(reportId, reportParamsRef.current.signal);
		},
		enabled: !!reportDetails?.id,
		refetchOnWindowFocus: false,
		retry: false,
		staleTime: 6 * 60 * 60 * 1000
	});

	const { data: binary, isLoading: binaryIsLoading } = useQuery({
		queryKey: ['report_generated', { submitedParams }],
		queryFn: async () => {
			reportGenerateRef.current?.abort();
			reportGenerateRef.current = new AbortController();

			try {
				const report = await generateReport(reportId, submitedParams, reportGenerateRef.current?.signal);

				return new Blob([report.data], { type: report.headers['content-type'] ?? 'text/plain' });
			} catch (e) {
				console.log(e);
			}
		},
		staleTime: 6 * 60 * 60 * 1000,
		retry: false,
		refetchOnWindowFocus: false,
		enabled: !!submitedParams
	});

	return (
		<>
			{reportDetailsIsLoading ?
				<ReportSkeleton /> :
				<div className={classNames('report', { '--is_loading': binaryIsLoading })}>
					<div className="title">
						<span>Relatório - {reportDetails?.label ?? reportDetails?.name}</span>
						{auth.user?.role_id === ROLE_OPERATION &&
							<button onClick={() => { reportModal.setActiveReportId(reportId); reportModal.setUpdateReportModalIsOpen(true); }}>
								<PencilSquareIcon />
							</button>
						}
					</div>
					<Form className="params" onFinish={setSubmitedParams}>
						<span className="title">Parâmetros</span>
						<div className="fields">
							{data?.map((param, index) => <Field reportParams={param} key={index} />)}
						</div>
						<div className="actions">
							<button className="generate" type="submit" disabled={isLoading}>Gerar</button>
						</div>
					</Form>
					<PdfViewer fileBinary={binary} />
				</div>
			}
		</>
	);
}

const Field = ({ reportParams: { name, required, type, route, show_label: showLabel, placeholder } }: { reportParams: IReportParams }) => {
	const avaliableFields: Record<IInputType, ReactNode> = {
		date: <FieldInput type='date' name={name} required={required} placeholder={placeholder} showLabel={showLabel} />,
		input: <FieldInput type='text' name={name} required={required} placeholder={placeholder} showLabel={showLabel} />,
		select: <FieldsSelect name={name} required={required} placeholder={placeholder} showLabel={showLabel} route={route} />
	}

	return (<div className="field">{avaliableFields[type]}</div>);
}