import { useQuery } from "@tanstack/react-query";
import { getOccurrenceDetailsByOccurrenceId, occurrenceDetailsQueryKey } from "./utils";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";

import OccurrenceDetails from "../OccurrenceDetails";
import Button from "../../../components/Button";
import UpdateOccurrenceModal from "./UpdateOccurrenceModal";
import { IOccurrence } from "../../../types/occurrence";
import { RESOLVED } from "../../../constants/occurrence";

const OccurrenceDetailsModal = ({
    occurrenceId,
    setOccurrenceId,
    setOccurrenceDetailsIsLoading
}: {
    occurrenceId?: number;
    setOccurrenceId: Dispatch<SetStateAction<number | undefined>>;
    setOccurrenceDetailsIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
    const occurrenceDetailsRequestRef = useRef<AbortController | null>(null);

    const [updateOccurrence, setUpdateOccurrence] = useState<IOccurrence | undefined>(undefined);

    const { data, isLoading } = useQuery({
        queryKey: occurrenceDetailsQueryKey(occurrenceId),
        queryFn: () => {
            occurrenceDetailsRequestRef.current?.abort();
            occurrenceDetailsRequestRef.current = new AbortController();

            return getOccurrenceDetailsByOccurrenceId(occurrenceId as number, occurrenceDetailsRequestRef.current.signal);
        },
        enabled: !!occurrenceId,
        retry: false
    });

    useEffect(() => { occurrenceId && setOccurrenceDetailsIsLoading(isLoading); }, [occurrenceId, isLoading]);

    return (
        <>
            <Modal isOpen={!!occurrenceId && !isLoading} onClose={() => setOccurrenceId(undefined)} size="xl">
                <ModalOverlay>
                    <ModalContent className="modal_content" style={{ borderRadius: '2px' }}>
                        <ModalHeader style={{ fontSize: '16px', textAlign: 'center', padding: '.5rem' }}>Detalhes da Ocorrência</ModalHeader>
                        <ModalBody style={{ padding: 0, borderRadius: 0 }}>
                            {data && <OccurrenceDetails occurrence={data} />}
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between', padding: '.5rem' }}>
                            <div>
                                {data?.status_id !== RESOLVED &&
                                    <Button
                                        color="warning"
                                        content='Atualizar Ocorrência'
                                        style={{ padding: '0 .8rem' }}
                                        onClick={() => data && setUpdateOccurrence(data)}
                                    />
                                }
                            </div>
                            <div>
                                <Button
                                    color="primary"
                                    content='Fechar'
                                    style={{ padding: '0 .8rem' }}
                                    onClick={() => setOccurrenceId(undefined)}
                                />
                            </div>
                        </ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
            <UpdateOccurrenceModal
                occurrence={occurrenceId ? updateOccurrence : undefined}
                setOccurrence={setUpdateOccurrence}
            />
        </>
    );
}

export default OccurrenceDetailsModal;