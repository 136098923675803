import { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";
import { setActiveHospitalStorage } from "./ActiveHospital/utils";

import api from "../services/api";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const userId = localStorage.getItem("User_id_senne")
  const [user, setUser] = useState(null)

  useEffect(() => {
    const getData = async () => {
      if (!userId) return
      try {
        if(userId){
          const response = await api.get(`/get/user/${userId}`)
          setUser(response.data.user)
        }
      } catch (error) {
        console.error(error)
      }
    }
    getData()
  }, [])

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("Auth_senne") ? true : false,
  );

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("Auth_senne");
    localStorage.removeItem("User_senne");
    localStorage.removeItem("User_id_senne");
    localStorage.removeItem("hospital");
    localStorage.removeItem("hospitalName");
    localStorage.removeItem("permissions");
    setActiveHospitalStorage(undefined);

    setUser("");
  };

  const signIn = async (data, role_id) => {
    try {
      setIsLoggedIn(false);
      localStorage.removeItem("Auth_senne");
      localStorage.removeItem("User_senne");
      localStorage.removeItem("User_id_senne");
      localStorage.removeItem("hospital");
      localStorage.removeItem("hospitalName");
      localStorage.removeItem("permissions");
      setUser("");

      const response = await api.post(`/auth/login/${role_id}`, data);

      if (response.data.token && !data.protocol && response.data.user) {
        setIsLoggedIn(true);
        localStorage.setItem("Auth_senne", response.data.token);
        localStorage.setItem("User_id_senne", JSON.stringify(response.data.user.id));
        setUser(response?.data?.user);
      }

      if (response.data.token && !response.data.user) {
        localStorage.setItem("Auth_senne", response.data.token);
      }

      return response;
    } catch (error) {
      setIsLoggedIn(false);

      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error?.response?.data;
    }
  };

  const resetPassword = async (data) => {
    try {
      const response = await api.post("/password/reset", data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const validateUser = async (data) => {
    try {
      const response = await api.post("/auth/validate/user", data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const validateSMS = async (data) => {
    try {
      const response = await api.post("/auth/validate/sms", data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const validateLogin = async (data) => {
    try {
      const response = await api.post("/auth/validate/code", data);

      if (response.data.token && !data.protocol && response.data.user) {
        setIsLoggedIn(true);
        localStorage.setItem("Auth_senne", response.data.token);
        localStorage.setItem("User_id_senne", JSON.stringify(response.data.user.id));
        setUser(response?.data?.user);
      }

      if (response.data.token && !response.data.user) {
        localStorage.setItem("Auth_senne", response.data.token);
      }

      return response;
    } catch (error) {
      toast.error("Token inválido ou expirado."); 
    }
  };

  const resendSMS = async (data) => {
    try {
      const response = await api.post("/auth/resend/sms", data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("Auth_senne");
    if (!user && !token) {
      logout();
    }
  }, [user]);
  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        logout,
        signIn,
        resetPassword,
        validateUser,
        validateSMS,
        resendSMS,
        validateLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
