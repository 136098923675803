import Input from '../../../../components/Input';

import './style.scss';

const FieldInput = ({
    name,
    placeholder,
    required,
    showLabel,
    type
}: {
    name: string;
    placeholder: string;
    type?: 'text' | 'password' | 'date';
    required?: boolean;
    showLabel?: boolean;
}) => {
    return (
        <div className='fields_input'>
            <Input
                name={name}
                label={!showLabel ? undefined : placeholder}
                placeholder={showLabel ? undefined : placeholder}
                required={required}
                theme='legacy'
                type={type}
            />
        </div>
    );
}

export default FieldInput;