import { useState, useEffect } from "react";

import { RegisterButton } from "./Buttons";

function CountdownTimer({ endTime, onTimeExpired }) {
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getRemainingTime();
      setRemainingTime(remaining);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getRemainingTime() {
    const currentTime = new Date();
    const end = new Date(endTime);
    const timeDifference = end - currentTime;

    if (timeDifference > 0) {
      let seconds = Math.floor((timeDifference / 1000) % 60);
      if (`${seconds}`.length === 1) { seconds = `0${seconds}` }
      const minutes = Math.floor((timeDifference / 1000 / 60) % 60);

      return (
        <RegisterButton background="var(--cinza-card)">
          {minutes}:{seconds}
        </RegisterButton>
      );
    } else {
      if (onTimeExpired) onTimeExpired();
      return (
        <RegisterButton background="var(--cinza-card)">00:00</RegisterButton>
      );
    }
  }

  return <div>{remainingTime}</div>;
}

export default CountdownTimer;
