import api from "../../../../services/api";

import { OccurrenceStatuses } from "../../../../types/occurrence";

export const updateOccurrence = async (
    occurrenceId: number,
    occurrence: string,
    statusId: OccurrenceStatuses
) => {
    return (await api.put<{ log_id: number; occcurrence_id: number; }>('/schedulling/occurrence', {
        occurrence: occurrence,
        status_id: statusId,
        occurrence_id: occurrenceId
    })).data;
}