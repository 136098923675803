import Badge from '../../components/Badge';
import Button from '../../components/Button';
import FloatBox from '../../components/FloatBox';
import Form from '../../components/Form';
import FilterHospital from './Filter/Hospital';
import FilterDoctor from './Filter/Doctor';
import FilterReason from './Filter/Reason';
import Input from '../../components/Input';
import OccurrenceDetailsModal from './OccurrenceDetailsModal';
import OccurrenceSkeleton from './OccurrenceSkeleton';
import Select from '../../components/Select';

import { format } from 'date-fns';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OCCURRENCE_STATUSES_COLOR, OCCURRENCE_STATUSES_CONTENT, OCCURRENCE_STATUSES_LIST } from '../../constants/occurrence';
import { useEffect, useMemo, useRef, useState } from 'react';
import { filterOccurrences, filterOccurrencesParamsQueryKey, filterOccurrencesQueryKey } from './utils';
import { Table } from '../../components/Table';

import './style.scss';

const Occurrence = () => {
    const occurrenceRequestAbortControllerRef = useRef<AbortController | null>(null);

    const queryClient = useQueryClient();

    const [activeOccurrenceId, setActiveOccurrenceId] = useState<number | undefined>(undefined);
    const [occurrenceDetailsIsLoading, setOccurrenceDetailsIsLoading] = useState(false);

    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const [filterParams, setFilterParams] = useState({});

    const { data: occurrence, isLoading: occurrenceIsLoading } = useQuery({
        queryKey: filterOccurrencesQueryKey(filterParams),
        queryFn: async () => {
            occurrenceRequestAbortControllerRef.current?.abort();
            occurrenceRequestAbortControllerRef.current = new AbortController();

            return await filterOccurrences(filterParams);
        },
        staleTime: 5 * 60 * 1000,
        retry: false
    });

    const hospital = useMemo(() => <FilterHospital />, []);
    const doctor = useMemo(() => <FilterDoctor />, []);
    const reason = useMemo(() => <FilterReason />, []);
    const statuses = useMemo(() =>
        <Select
            clearButton
            label='Status da Ocorrência'
            theme='legacy'
            multiple
            name='status_ids'
            options={OCCURRENCE_STATUSES_LIST.map(
                ocurrence => ({
                    value: ocurrence,
                    label: <Badge
                        color={OCCURRENCE_STATUSES_COLOR[ocurrence]}
                        children={OCCURRENCE_STATUSES_CONTENT[ocurrence]}
                    />
                })
            )}
        />, []
    );

    useEffect(() => { queryClient.setQueryData(filterOccurrencesParamsQueryKey(), filterParams); }, [filterParams]);

    return (
        <div className="page_occurrence">
            <FloatBox
                theme='modern'
                title='Filtro'
                filterIsOpen={filterIsOpen}
                setFilterIsOpen={setFilterIsOpen}
            >
                <Form className='occurrence_filter_fields' onFinish={(params) => { setFilterParams(params); setFilterIsOpen(false) }}>
                    <div className='field'>
                        {statuses}
                    </div>
                    <div className='field'>
                        {reason}
                    </div>
                    <div className='field'>
                        {hospital}
                    </div>
                    <div className='field'>
                        {doctor}
                    </div>
                    <div className='field'>
                        <Input
                            label='Data Inicial'
                            name='dt_start'
                            type='date'
                            theme='legacy'
                        />
                        <Input
                            label='Data Final'
                            name='dt_end'
                            type='date'
                            theme='legacy'
                        />
                    </div>
                    <div className='actions'>
                        <Button
                            content='Filtrar'
                            color='primary'
                            type='submit'
                            label=''
                            isLoading={occurrenceIsLoading}
                        />
                    </div>
                </Form>
            </FloatBox>
            <div className='occurrence_container'>
                <div className='occurrences'>
                    <div className='title'>
                        Ocorrências
                    </div>
                    <div className='list'>
                        {occurrenceIsLoading ?
                            <OccurrenceSkeleton /> :
                            <Table
                                hover
                                thead={[
                                    { content: '#' },
                                    { content: 'Status' },
                                    { content: 'Motivo' },
                                    { content: 'Responsável' },
                                    { content: 'Ocorrência' },
                                    { content: 'Médico' },
                                    { content: 'Hospital' },
                                    { content: 'Criado em' }
                                ]}
                                tbody={occurrence?.occurrences.map(occurrence => ({
                                    isLoading: occurrenceDetailsIsLoading && occurrence.occurrence_id === activeOccurrenceId,
                                    elements: [
                                        { content: occurrence.occurrence_id },
                                        { content: <Badge color={OCCURRENCE_STATUSES_COLOR[occurrence.status_id]} children={OCCURRENCE_STATUSES_CONTENT[occurrence.status_id]} /> },
                                        { content: occurrence.reason },
                                        { content: occurrence.responsible },
                                        { content: occurrence.occurrence },
                                        { content: occurrence.medico_name },
                                        { content: occurrence.hospital_name },
                                        { content: format(new Date(occurrence.created_at), 'dd/MM/yyyy \'às\' HH:mm') }
                                    ],
                                    onClick: () => setActiveOccurrenceId(occurrence.occurrence_id)
                                })) ?? []}
                            />
                        }
                        {occurrence?.total === 0 && <div className='empty-message'>Nenhuma ocorrência encontrada</div>}
                    </div>
                </div>
            </div>
            <OccurrenceDetailsModal
                occurrenceId={activeOccurrenceId}
                setOccurrenceId={setActiveOccurrenceId}
                setOccurrenceDetailsIsLoading={setOccurrenceDetailsIsLoading}
            />
        </div>
    );
}

export default Occurrence;