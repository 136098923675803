import { forwardRef, Ref } from "react";
import { IInput } from "./types";

import './style.scss';

const Input = forwardRef((
    {
        theme,
        label,
        placeholder,
        defaultValue,
        name,
        onChange,
        required,
        type
    }: IInput,
    ref: Ref<HTMLInputElement>
) => {
    theme = theme ? theme : 'modern';

    return (
        <div className={`ctm_input --${theme}`}>
            {label !== undefined && <label>{label}</label>}
            <input
                ref={ref}
                defaultValue={defaultValue}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                required={required}
                type={type}
                autoComplete="off"
            />
        </div>
    );
});

export default Input;