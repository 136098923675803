import { useEffect, useRef } from "react";
import styled from "styled-components";

interface IModalProps {
    children: React.ReactNode;
    closeModal: () => void;
}

const ModalOverlay = styled.div`
    position: fixed;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000000000;

    inset: 0;

    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.3);
`


const ModalBox = styled.div`
    position: relative;
    min-width: fit-content;
    max-width: 70vw;
    background: #fff;
    padding: 2rem 1rem;
`

const CloseButton = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    font-size: 1.5rem;
`

export const Modal: React.FC<IModalProps> = ({ children, closeModal }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleOutclick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                closeModal()
            }
        }

        window.addEventListener("mousedown", handleOutclick);

        return () => {
            window.removeEventListener("mousedown", handleOutclick);
        }
    }, [])


    useEffect(() => {
        const handleKeydown = (event: any) => {
            if (event.key === 'Escape') {
                closeModal()
            }
        }

        window.addEventListener("keydown", handleKeydown);

        return () => {
            window.removeEventListener("keydown", handleKeydown);
        }
    }, [])

    return (
        <ModalOverlay>
            <ModalBox ref={modalRef}>
                <CloseButton onClick={closeModal}>X</CloseButton>
                {children}
            </ModalBox>
        </ModalOverlay>
    )
}