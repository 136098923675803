import { Color } from "Color";
import { OccurrenceStatuses } from "../types/occurrence";

export const UNRESOLVED = 1;
export const INPROGRESS = 2;
export const RESOLVED = 3;

export const OCCURRENCE_STATUSES_CONTENT: Record<OccurrenceStatuses, string> = {
    [UNRESOLVED]: 'Não Resolvido',
    [INPROGRESS]: 'Em Progresso',
    [RESOLVED]: 'Resolvido',
};

export const OCCURRENCE_STATUSES_COLOR: Record<OccurrenceStatuses, Color> = {
    [UNRESOLVED]: 'danger',
    [INPROGRESS]: 'warning',
    [RESOLVED]: 'success',
};

export const OCCURRENCE_STATUSES_LIST: OccurrenceStatuses[] = [
    UNRESOLVED,
    INPROGRESS,
    RESOLVED
];