import ChangeActiveHospitalModal from "./ChangeActiveHospitalModal";

import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IActiveHospital, IActiveHospitalProvider, IContext } from "./types";
import { getActiveHospitalStorage, getLastHospitalSelected, setActiveHospitalStorage } from "./utils";
import { useAuth } from "../../hooks";
import { Role, ROLE_DOCTOR, ROLE_PATIENT } from "../../types/role";

export const ActiveHospitalContext = createContext<IContext>({} as IContext);

export const ActiveHospitalProvider = ({ children }: IActiveHospitalProvider) => {
    const auth = useAuth() as { user: { id: number; role_id: Role; hash: string; } };

    const getLastHospitalSelectedRequestRef = useRef<AbortController | null>(null);

    const [activeHospital, setActiveHospital] = useState<IActiveHospital | undefined>();
    const [changeActiveHospitalIsOpen, setChangeActiveHospitalIsOpen] = useState<boolean>(false);

    const getLastHospitalSelectedCallback = useCallback(async () => {
        const lastHospitalSelected = await getLastHospitalSelected();

        if (lastHospitalSelected) {
            setActiveHospital({
                id: parseInt(lastHospitalSelected.id),
                name: lastHospitalSelected.name,
                permissions: lastHospitalSelected.permissions,
                uuid: lastHospitalSelected.uuid
            });
            return;
        }

        setChangeActiveHospitalIsOpen(true);
    }, []);

    useEffect(() => {
        if (
            auth.user?.role_id !== ROLE_DOCTOR &&
            auth.user?.role_id !== ROLE_PATIENT
        ) {
            const activeHospitalFromStorage = getActiveHospitalStorage();

            activeHospitalFromStorage && setActiveHospital(activeHospitalFromStorage);
            !activeHospitalFromStorage && getLastHospitalSelectedCallback();
        }

        if (
            auth.user?.role_id === ROLE_DOCTOR ||
            auth.user?.role_id === ROLE_PATIENT
        ) {
            setActiveHospital({ uuid: auth.user?.hash });
        }
    }, [auth.user?.role_id, auth.user?.hash]);

    useEffect(() => {
        setActiveHospitalStorage(activeHospital);
    }, [activeHospital]);

    useEffect(() => { getLastHospitalSelectedRequestRef.current?.abort(); }, [activeHospital, changeActiveHospitalIsOpen]);

    const changeActiveHospitalModal = useMemo(
        () => (
            auth.user?.role_id !== ROLE_DOCTOR &&
            auth.user?.role_id !== ROLE_PATIENT
        ) ?
            <ChangeActiveHospitalModal
                changeActiveHospitalIsOpen={changeActiveHospitalIsOpen}
                setChangeActiveHospitalIsOpen={setChangeActiveHospitalIsOpen}
                activeHospital={activeHospital}
                setActiveHospital={setActiveHospital}
            /> :
            <></>,
        [
            auth.user?.role_id,
            changeActiveHospitalIsOpen,
            setChangeActiveHospitalIsOpen,
            activeHospital,
            setActiveHospital
        ]
    );

    return (
        <ActiveHospitalContext.Provider value={{ ...activeHospital, setChangeActiveHospitalIsOpen }}>
            {changeActiveHospitalModal}
            {children}
        </ActiveHospitalContext.Provider>
    );
}
