import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { getHospitals } from "../contexts/ActiveHospital/ChangeActiveHospitalModal/utils";

export const useHospitalList = (userId: number) => {
    const getHospitalsRequestRef = useRef<AbortController | null>(null);

    return useQuery({
        queryKey: ['hospital_list', { user_id: userId }],
        queryFn: async () => {
            getHospitalsRequestRef.current?.abort();
            getHospitalsRequestRef.current = new AbortController();

            return await getHospitals(getHospitalsRequestRef.current.signal);
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 6 * 60 * 60 * 1000
    });
}