import { MouseEvent, ReactNode } from "react";
import { Color } from "Color";

import './style.scss';

const Badge = ({
    children,
    color,
    className,
    onClick,
    title
}: {
    children: ReactNode,
    color: Color,
    onClick?: (event: MouseEvent<HTMLDivElement>) => void,
    title?: string,
    className?: string
}) => {
    return (
        <div
            className={`badge --${color} ${onClick ? '--clickable' : ''} ${className}`}
            children={children}
            onClick={onClick}
            title={title}
        />
    );
}

export default Badge;