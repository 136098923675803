import TemplateRegister from "../../components/TemplateRegister";
import styled from "styled-components";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GoBackButton, RegisterButton } from "../../components/Buttons";
import { Flex, Text } from "@chakra-ui/react";
import Paciente from "../../images/paciente.svg";
import Hospitais from "../../images/hospitais.svg";
import Medicos from "../../images/medicos.svg";
import Demais from "../../images/demaisParfeiros.svg";

export default function Register() {
  const Navigate = useNavigate();
  const [selected, setSelected] = useState();

  function links(number) {
    if (number === 0) {
      Navigate("/cadastro/hospital");
    }
    if (number === 1) Navigate("/cadastro/medico");
    if (number === 2) Navigate("/cadastro/parceiros");
  }

  const cadastro = [
    { image: Hospitais, Text: "Corpo Clínico e Colaboradores dos Hospitais" },
    { image: Medicos, Text: "Médicos - acesso APENAS a resultados prescritos por você" },
    // { image: Demais, Text: "Demais Parceiros" },
  ];
  return (
    <>
      <TemplateRegister>
        <Content>
          <Flex
            flexDirection="column"
            backgroundColor="white"
            padding="10px 100px"
            borderRadius="8px"
          >
            <Flex
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              mt="41px"
              mb="34px"
              gap="10px"
            >
              <h1 fontSize="40px">Faça seu Cadastro</h1>
              <h2>Escolha qual tipo de cadastro deseja realizar</h2>
            </Flex>

            <Flex gap="30px" justifyContent="center" flexWrap="wrap">
              {cadastro?.map((item, index) => {
                return (
                  <Flex
                    flexDirection="column"
                    border="1px solid gray"
                    borderRadius="7px"
                    w="185px"
                    h="100%"
                    backgroundColor={
                      index === selected
                        ? "var(--orange-senne)"
                        : "var(--cinza-bg)"
                    }
                    key={index}
                    onDoubleClick={() => links(selected)}
                    onClick={() => setSelected(index)}
                    cursor="pointer"
                    _hover={{ opacity: "0.7" }}
                  >
                    <Flex
                      padding="20px 0"
                      flexDirection="column"
                      borderRadius="7px"
                      w="178px"
                      h="178px"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <img src={item.image} w="100%" alt="" />

                      <Text textAlign="center" fontSize="1rem" w="100%">
                        {item.Text}
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>

            <Flex
              mt="50px"
              sx={{
                "@media (max-width: 800px)": {
                  paddingBottom: "100px",
                },
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Link to="/">
                <GoBackButton />
              </Link>

              <RegisterButton onClick={() => links(selected)}>
                Continuar
              </RegisterButton>
            </Flex>
          </Flex>
        </Content>
      </TemplateRegister>
    </>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 50px 50px 0 50px;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
`;
