import classNames from 'classnames';

import { useState } from 'react';
import { ITabs } from './types';

import './style.scss';

const Tabs = ({ tabs, color, style }: ITabs) => {

    const [tabIndexActive, setTabIndexActive] = useState<number>(0);

    const tabsTitle = tabs.map(tab => tab.title);

    style = style ? style : 'modern';
    color = color ? color : 'primary--light';

    return (
        <div className={`tabs --${style} --${color}`}>
            <nav>
                <ul>
                    {tabsTitle.map((title, index) =>
                        <li
                            className={classNames({ '--active': tabIndexActive === index })}
                            key={index}
                            content={title}
                            onClick={() => setTabIndexActive(index)}
                            children={title}
                        />
                    )}
                </ul>
            </nav>
            <main>
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={classNames({ '--active': tabIndexActive === index })}
                        children={tab.content}
                    />
                ))}
            </main>
        </div>
    );
}

export default Tabs;