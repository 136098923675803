import Button from "../../../../components/Button";
import OccurrenceDetails from "../../OccurrenceDetails";
import Form from "../../../../components/Form";
import Select from "../../../../components/Select";
import Textarea from "../../../../components/Textarea";
import Badge from "../../../../components/Badge";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { IListOccurrence, IOccurrence, IOccurrenceWithLogs, OccurrenceStatuses } from "../../../../types/occurrence";
import { OCCURRENCE_STATUSES_COLOR, OCCURRENCE_STATUSES_CONTENT, OCCURRENCE_STATUSES_LIST, RESOLVED } from "../../../../constants/occurrence";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { filterOccurrencesParamsQueryKey, filterOccurrencesQueryKey } from "../../utils";
import { updateOccurrence } from "./utils";
import { occurrenceDetailsQueryKey } from "../utils";
import { useAuth } from "../../../../hooks";

import './style.scss';

const UpdateOccurrenceModal = ({
    occurrence,
    setOccurrence
}: {
    occurrence?: IOccurrence;
    setOccurrence: Dispatch<SetStateAction<IOccurrence | undefined>>;
}) => {
    const queryClient = useQueryClient();
    const auth = useAuth() as { user: { id: number } };

    const sendUpdateOccurrence = async (params: { occurrence: string; status_id: '1' | '2' | '3'; }) => {

        const { occurrence: occurrenceContent, status_id } = params;

        if (!occurrence) {
            toast.error('Não há uma ocorrência de referência para atualização');
            return;
        }

        const convertStatusId = (statusId: string) => {
            return parseInt(statusId) as OccurrenceStatuses;
        }

        const response = await updateOccurrence(occurrence.occurrence_id, params.occurrence, convertStatusId(params.status_id));

        const filterParams = queryClient.getQueryData<{}>(filterOccurrencesParamsQueryKey()) ?? {};

        queryClient.setQueryData<IListOccurrence>(
            filterOccurrencesQueryKey(filterParams),
            (prev) => {
                if (!prev || prev.total === 0) {
                    return { occurrences: [{ ...occurrence, occurrence: occurrenceContent, status_id: convertStatusId(status_id) }], total: 1 }
                }

                const updatedOccurrence = prev.occurrences.map(prevOccurrence => {
                    if (prevOccurrence.occurrence_id === occurrence.occurrence_id) {
                        return { ...prevOccurrence, occurrence: occurrenceContent, status_id: convertStatusId(status_id) };
                    }

                    return prevOccurrence;
                });

                return { ...prev, occurrences: updatedOccurrence };
            }
        );


        queryClient.setQueryData<IOccurrenceWithLogs>(
            occurrenceDetailsQueryKey(occurrence.occurrence_id),
            (prev) => {
                if (!prev) {
                    return undefined;
                }

                const updatedLogs = [...prev.logs.logs];

                updatedLogs.unshift({
                    id: response.log_id,
                    occurrence_id: response.occcurrence_id,
                    json: {
                        ID: response.log_id,
                        OCCURRENCE: occurrence.occurrence,
                        SCHEDULE_ID: occurrence.schedule_id,
                        CD_MEDICO: occurrence.cd_medico,
                        STATUS_ID: convertStatusId(typeof occurrence.status_id === 'number' ? occurrence.status_id.toString() : occurrence.status_id),
                        UPDATED_AT: occurrence.created_at,
                        CREATED_AT: occurrence.created_at,
                        USER_ID: occurrence.user_id,
                        REASON: occurrence.reason,
                        REASON_ID: occurrence.reason_id
                    }
                });

                return {
                    ...prev,
                    occurrence: occurrenceContent,
                    status_id: convertStatusId(status_id),
                    logs: { total: prev.logs.total + 1, logs: updatedLogs }
                };
            }
        );

        setOccurrence(undefined);
    }

    return (
        <Modal isOpen={!!occurrence} onClose={() => setOccurrence(undefined)} size="xl">
            <ModalOverlay>
                <Form onFinish={sendUpdateOccurrence}>
                    <ModalContent className="update_occurrence_modal_content" style={{ borderRadius: '2px' }}>
                        <ModalHeader style={{ fontSize: '16px', textAlign: 'center', padding: '.5rem' }}>Atualizando Ocorrência</ModalHeader>
                        <ModalBody style={{ padding: 0, borderRadius: 0 }}>
                            <span className="title">Status Atual:</span>
                            <div className="occurrence">
                                {occurrence && <OccurrenceDetails occurrence={{ ...occurrence, logs: { logs: [], total: 0 } }} />}
                            </div>
                            <div className="update">
                                <div className="occurrece_field">
                                    <Textarea
                                        label="Atualiação da Ocorrência"
                                        placeholder="Descreva o novo status da ocorrência"
                                        name="occurrence"
                                        theme="legacy"
                                        required
                                    />
                                </div>
                                <div className="status_field">
                                    <Select
                                        label="Novo Status"
                                        name="status_id"
                                        theme="legacy"
                                        closeOnSelect
                                        required
                                        options={OCCURRENCE_STATUSES_LIST.map(status => {
                                            return {
                                                value: status,
                                                label: <Badge
                                                    color={OCCURRENCE_STATUSES_COLOR[status]}
                                                    children={OCCURRENCE_STATUSES_CONTENT[status]}
                                                />
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between', padding: '.5rem' }}>
                            <div>
                                {occurrence?.status_id !== RESOLVED &&
                                    <Button
                                        color="danger"
                                        content='Cancelar'
                                        style={{ padding: '0 .8rem' }}
                                        onClick={() => setOccurrence(undefined)}
                                    />
                                }
                            </div>
                            <div>
                                <Button
                                    color="success"
                                    content='Confirmar'
                                    style={{ padding: '0 .8rem' }}
                                    onClick={() => { }}
                                    type="submit"
                                />
                            </div>
                        </ModalFooter>
                    </ModalContent>
                </Form>
            </ModalOverlay>
        </Modal>
    )
}

export default UpdateOccurrenceModal;