import styled, { createGlobalStyle } from "styled-components";

const ButtonStyles = createGlobalStyle`
  button {
    padding: 16px 32px;
    border-radius: 8px;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: absolute;
      left: 30px;
      top: 12px;
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
    @media (max-width: 414px) {
      font-size: 14px;
    }
  }
`;

const Primary = styled.button`
  color: #fff;
  background: #ec6726;
  padding: 16px 32px;
  border-radius: 8px;
  margin: 16px 0px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const AzulSenne = styled.button`
  color: #fff;
  background: #0dcaf0;
  padding: 16px 32px;
  border-radius: 8px;
  margin: 16px 0px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const OrangeSenne = styled.button`
  color: #fff;
  background: var(--orange-senne);
  padding: 16px 32px;
  border-radius: 8px;
  margin: 16px 0px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const PrimaryResults = styled.button`
  color: #fff;
  background: #f3953f;
  padding: 4.5px 13px;
  border-radius: 4px;
  margin: auto;
  border: none;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 20px;
    top: 5px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const Cancel = styled.button`
  color: #fff;
  background: #343a40;
  padding: 4.5px 13px;
  border-radius: 4px;
  margin: auto;
  border: none;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 10px;
    top: 5px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const Gray = styled.button`
  color: #fff;
  background: #c4c4c4;
  padding: 16px 32px;
  border-radius: 8px;
  margin: 16px 0px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const PrimaryTable = styled.button`
  color: #fff;
  background: #f3953f;
  padding: 4.5px 13px;
  border-radius: 4px;
  margin: auto;
  border: none;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: relative;
    top: 0;
    left: -4px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const Register = styled.button`
  color: #fff;
  background: ${(props) => (props.background ? props.background : "#ec6726")};
  border-radius: 45px;
  padding: 6px;
  margin: 16px 0px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  width: 168px;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
  :disabled {
    opacity: 0.5;
  }
`;

const Secondary = styled.button`
  color: #ec6726;
  background: #fff;
  padding: 16px 32px;
  border-radius: 8px;
  margin: 16px 0px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const Outline = styled.button`
  color: #ec6726;
  background: #fff;
  border: 1px solid #ec6726;
  padding: 16px 32px;
  border-radius: 8px;
  margin: 16px 0px;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

const Green = styled.button`
  color: #fff;
  background: #b9bd5c;
  padding: 16px 32px;
  border-radius: 8px;
  margin: 16px 0px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 30px;
    top: 12px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;
const Loggin = styled.div`
  span{
    display: flex;
    justify-content: center;
    flex-grow: 1; 
    text-align: center;
  }
  display: flex;
  background: ${(props) =>
    props.background ? props.background : "var(--green-senne)"};
  padding: 8px 32px;
  border-radius: 45px;
  margin: 15px 0px;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  img {
    left: 30px;
    top: 12px;
    width: 20px;
    height: 20px;
  }
  @media (max-width: 950px) {
    font-size: 0.8rem;
    img {
      left: 30px;
      top: 12px;
      width: 15px;
      height: 15px;
    }
  }
  @media (max-width: 800px) {
    flex-wrap: wrap; 
    display: flex;
  }
  @media (max-width: 500px) {
    flex-wrap: wrap; 
    display: flex;
    column-gap: .5rem;
    span{
      display: flex;
      justify-content: unset; 
      flex-grow: unset; 
      text-align: unset;     
    }
  }
`;

const Back = styled.button`
  border-radius: 8px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: fit-content;
  max-height: fit-content;

  padding: 0px 0px;
  color: #343a40;
  background: #fff;
  width: 100px;
  /* margin: 32px 0 20px; */
  img {
    top: 5px;
    left: 0;
    width: 16px;
    height: 16px;
  }
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

export {
  ButtonStyles,
  Primary,
  AzulSenne,
  Secondary,
  Green,
  Gray,
  Back,
  Outline,
  Register,
  PrimaryResults,
  PrimaryTable,
  Cancel,
  OrangeSenne,
  Loggin,
};
