import classNames from "classnames";

import { formatDate } from "../../../helpers";
import { Table } from "../../Table";
import { useNavigate } from "react-router-dom";
import { IExams } from "../types";

const Result = ({ results, uuidSelected }: { results: IExams[], uuidSelected?: string }) => {
  const navigate = useNavigate();

  return (
    <>
      <Table
        maxHeightFix={24.5}
        emptyMessage="Não existem dados com essa Data. Por favor selecione uma data diferente"
        hover
        thead={[
          { content: 'Nome do Paciente' },
          { content: 'Nº de Atendimento' },
          { content: 'Procedência' },
          { content: 'Médico Solicitante' },
          { content: 'Data de Coleta' },
          { content: 'Previsão de Conclusão' },
          { content: 'Status' }
        ]}
        tbody={
          results.map(exam => {
            const entryDate = formatDate(exam.dataentrada);
            const resultDate = formatDate(exam.dataresultado);

            return {
              elements: [
                {
                  content: exam.nomepaciente,
                  className: classNames('patient', { '--has_error': exam.status === 3 }),
                  tooltip: true
                },
                { content: exam.numatendimento },
                { content: exam.nomeprocedencia, tooltip: true },
                { content: `${exam.nomemedico}${exam.CRMDoctor ? ` - ${exam.CRMDoctor}` : ''}`, tooltip: true },
                { content: entryDate, tooltip: true },
                { content: resultDate, tooltip: true },
                {
                  content: <div children={<div style={{ backgroundColor: exam.progressbackgroudcolor, width: exam.status }} />} />,
                  className: '--status_progress'
                }
              ],
              onClick: () => (exam.numatendimento && uuidSelected) && navigate(`/resultados-exames/${uuidSelected}/${exam?.numatendimento}`)
            }
          })
        }
      />
    </>
  )
}

export default Result;