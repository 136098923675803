import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../../hooks";
import { getHospitals } from "../../../../contexts/ActiveHospital/ChangeActiveHospitalModal/utils";

import Select from "../../../../components/Select";

const FilterHospital = () => {
    const auth = useAuth() as { user: { id: number } };
    const getHospitalsRequestRef = useRef<AbortController | null>(null);

    const { data, isLoading } = useQuery({
        queryKey: ['hospital_list', { user_id: auth.user?.id }],
        queryFn: async () => {
            getHospitalsRequestRef.current?.abort();
            getHospitalsRequestRef.current = new AbortController();

            return await getHospitals(getHospitalsRequestRef.current.signal);
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 6 * 60 * 60 * 1000
    });

    return (
        <Select
            clearButton
            label='Hospital'
            name='hospital_ids'
            isLoading={isLoading}
            multiple
            theme='legacy'
            options={data?.map(hospital => ({ value: hospital.codprocedencia, label: hospital.name })) ?? [{ value: '', label: 'Nenhuma Opção Disponível' }]}
            javascriptSearch
        />
    );
}

export default FilterHospital;