import classNames from 'classnames';

import { ICollapseable as IFloatBox } from './types';
import { useEffect, useRef, useState } from 'react';

import './style.scss';

const FloatBox = ({
    theme,
    title,
    children,
    color,
    closeOnClickOutside,
    filterIsOpen,
    setFilterIsOpen
}: IFloatBox) => {
    const floatBoxRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(filterIsOpen ?? false);

    theme = theme ?? 'modern';

    useEffect(() => { setIsOpen(filterIsOpen ?? false) }, [filterIsOpen]);

    useEffect(() => {
        let clicked = false;

        const handleClickOutside = (e: MouseEvent) => {
            if (clicked) {
                return;
            }

            clicked = true;

            if (closeOnClickOutside && !floatBoxRef.current?.contains(e.target as Node)) {
                setFilterIsOpen && setFilterIsOpen(false);
                setIsOpen(false);
            }

            setTimeout(() => { clicked = false; }, 0);
        };

        window.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={`collapseable --${theme}${color ? ` --${color}` : ''}`} ref={floatBoxRef}>
            <header onClick={() => setFilterIsOpen ? setFilterIsOpen(prev => !prev) : setIsOpen(prev => !prev)}>
                <div className='title' children={title} />
            </header>
            <main
                className={classNames({ '--active': isOpen })}
                children={children}
            />
        </div>
    )
};

export default FloatBox;