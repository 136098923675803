import { Skeleton } from "@chakra-ui/react";
import { Table } from "../../../components/Table";

const OccurrenceSkeleton = () => {
    const thead = [
        { content: '#' },
        { content: 'Status' },
        { content: 'Motivo' },
        { content: 'Responsável' },
        { content: 'Ocorrência' },
        { content: 'Médico' },
        { content: 'Hospital' },
        { content: 'Criado em' }
    ];
    return (
        <Table
            thead={thead}
            tbody={Array.from({ length: 30 }).map(
                (_, index) => ({
                    elements: [
                        { content: <Skeleton height='20px' width='100%' /> },
                        { content: <Skeleton height='20px' width='100%' /> },
                        { content: <Skeleton height='20px' width='100%' /> },
                        { content: <Skeleton height='20px' width='100%' /> },
                        { content: <Skeleton height='20px' width='100%' /> },
                        { content: <Skeleton height='20px' width='100%' /> },
                        { content: <Skeleton height='20px' width='100%' /> },
                        { content: <Skeleton height='20px' width='100%' /> },
                    ]
                }))}
        />
    );
}

export default OccurrenceSkeleton;