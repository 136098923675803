import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { getReportParams, REPORT_INPUT_TYPE_LIST } from "../../../pages/Report/utils";
import { IInputType, IReportParams } from "../../../pages/Report/types";
import { createOrUpdateReport, createOrUpdateReportParams, getReportDetails, removeReport } from "./utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IReport } from "../../../components/Aside/types";
import { useActiveHospital } from "../../ActiveHospital/useActiveHospital";
import { useAuth } from "../../../hooks";
import { Role, ROLE_OPERATION } from "../../../types/role";
import { useReportModal } from "../useReportModal";
import { useNavigate } from "react-router-dom";

import Form from "../../../components/Form";
import classNames from "classnames";

import './style.scss';
import Select from "../../../components/Select";
import Input from "../../../components/Input";

export type OptionalReportParams = Partial<IReportParams>;

interface IReportParam extends OptionalReportParams {
    name?: string;
    placeholder?: string;
    type?: IInputType;
    required?: boolean;
    active?: boolean;
}

const DEFAULT_NEW_PARAM_MESSAGE = 'Novo Parâmetro';

const UpdateReportModal = ({
    activeReportId,
    updateReportModalIsOpen,
    setUpdateReportModalIsOpen
}: {
    activeReportId?: number;
    updateReportModalIsOpen: boolean;
    setUpdateReportModalIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const getReportDetailsRequestRef = useRef<AbortController | null>(null);
    const getReportParamsRequestRef = useRef<AbortController | null>(null);

    const navigate = useNavigate();
    const activeHospital = useActiveHospital();
    const queryClient = useQueryClient();
    const reportModal = useReportModal();
    const auth = useAuth() as { user: { id: number; role_id: Role; hash: string; name: string; hospitals: { name: string; }[]; }, logout: () => void };

    const [removeIsLoading, setRemoveIsLoading] = useState(false);
    const [removeReportConfirmation, setRemoveReportConfirmation] = useState(false);
    const [params, setParams] = useState<IReportParam[]>([]);
    const { data: reportDetails, isLoading: reportDetailsIsLoading } = useQuery({
        queryKey: ['report_details', { report_id: activeReportId }],
        queryFn: () => {
            getReportDetailsRequestRef.current?.abort();
            getReportDetailsRequestRef.current = new AbortController();

            return getReportDetails(activeReportId as number, getReportDetailsRequestRef.current.signal);
        },
        staleTime: 6 * 60 * 60 * 1000,
        enabled: !!activeReportId
    });

    const handleAddParam = () =>
        setParams(prev => {
            const params = [...prev];

            params.push({ placeholder: DEFAULT_NEW_PARAM_MESSAGE, active: true });

            return params.map((param, index) => ({ ...param, active: (index === (params.length - 1)) }));
        });

    const handleRemoveParam = (paramIndex: number) =>
        setParams(prev => [...prev].filter((_, index) => index !== paramIndex));

    const handleToggleActiveParam = (paramIndex: number) =>
        setParams(prev => [...prev].map((param, index) => ({ ...param, active: index === paramIndex && !param.active })));

    const handleChangeValue = (paramIndex: number, event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name as 'name' | 'placeholder' | 'type' | 'required' | 'active';
        const processedValue = event.target.type === 'checkbox' ? event.target.checked ? 1 : 0 : event.target.value;

        setParams(prev => [...prev].map((param, index) => ({ ...param, [name]: paramIndex === index ? processedValue : param[name] })));
    }

    const handleFinishUpdate = async (formParams: { report_name: string; report_label?: string; }) => {
        const { report_name: name, report_label: label } = formParams;

        const reportId = (await createOrUpdateReport(name, label, activeReportId)).report_id;

        await createOrUpdateReportParams(params, reportId);

        queryClient.setQueryData<IReport>(['report_details', { report_id: reportId }], { id: reportId, name: name, label: label });
        queryClient.setQueryData<IReport[]>(
            ['reports', auth.user?.role_id === 1 ? { role_id: 1 } : { uuid: activeHospital.uuid }],
            (prev) => {
                const newReport = { id: reportId, name: name, label: label };
                const reports = prev ? [...prev] : [];

                if (!activeReportId) {
                    reports.push(newReport);
                    return reports;
                }

                return prev?.map(report => {
                    const updatedReport = { ...report };

                    if (report.id === activeReportId) {
                        updatedReport.label = label;
                        updatedReport.name = name;
                    }

                    return updatedReport;
                });
            }
        );
        queryClient.setQueryData(['report_params', { reportId }], params);

        reportModal.setUpdateReportModalIsOpen(false);

        !activeReportId && navigate(`/relatorio/${reportId}`);
    }

    const handleRemoveReport = async () => {
        if (!activeReportId) {
            return;
        }

        try {
            setRemoveIsLoading(true);
            await removeReport(activeReportId);

            navigate('/dashboard');

            reportModal.setUpdateReportModalIsOpen(false);

            queryClient.setQueryData<IReport[]>(
                ['reports', auth.user?.role_id === 1 ? { role_id: 1 } : { uuid: activeHospital.uuid }],
                (prev) => prev ? [...prev].filter(report => report.id !== activeReportId) : []
            );
        } catch (e) { }

        setRemoveIsLoading(false);
    }

    const getReportParamsCallback = useCallback(async () => {
        getReportParamsRequestRef.current?.abort();
        getReportParamsRequestRef.current = new AbortController();

        if (!activeReportId) {
            return
        }

        const params = await getReportParams(activeReportId, getReportParamsRequestRef.current?.signal);

        setParams(params);
    }, [activeReportId]);

    useEffect(() => {
        if (activeReportId) {
            getReportParamsCallback();
        }

        setRemoveIsLoading(false);
        setRemoveReportConfirmation(false);
        setParams([]);
    }, [activeReportId, updateReportModalIsOpen]);

    return (
        <>
            <Modal
                onClose={() => setUpdateReportModalIsOpen(false)}
                isOpen={updateReportModalIsOpen}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent className="update_report__modal_content">
                    <header>
                        <span>{activeReportId ? 'Alterando' : 'Adicionando'} Relatório</span>
                    </header>
                    <main>
                        <Form className={classNames({ '--is_loading': reportDetailsIsLoading || removeIsLoading })} onFinish={handleFinishUpdate}>
                            <div className="fields">
                                <div className="field">
                                    <Input name="report_label" label="Nome do Relatório" defaultValue={reportDetails?.label} theme="legacy" required />
                                </div>
                                <div className="field">
                                    <Input name="report_name" label="Nome da Variável" defaultValue={reportDetails?.name} theme="legacy" required />
                                </div>
                                <div className="params">
                                    <div className="title">
                                        Parâmetros
                                    </div>
                                    <div className="params_addeded">
                                        {params.map((param, index) => {
                                            return (
                                                <div key={index} className={classNames('param', { '--active': param.active })}>
                                                    <div className="title" onClick={(() => handleToggleActiveParam(index))}>
                                                        {param.placeholder}
                                                    </div>
                                                    <div className="fields">
                                                        <div className="field">
                                                            <Input
                                                                name="placeholder"
                                                                label="Nome do Campo"
                                                                onChange={(e) => handleChangeValue(index, e)}
                                                                defaultValue={param.placeholder === DEFAULT_NEW_PARAM_MESSAGE ? '' : param.placeholder}
                                                                theme="legacy"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="field">
                                                            <Input
                                                                name="name"
                                                                label="Nome da Variável"
                                                                onChange={(e) => handleChangeValue(index, e)}
                                                                defaultValue={param.name}
                                                                theme="legacy"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="field">
                                                            <Select
                                                                defaultValue={param.type ? { value: param.type } : undefined}
                                                                options={REPORT_INPUT_TYPE_LIST.map(inputType => ({ value: inputType }))}
                                                                onChange={(option) => setParams(prev => {
                                                                    const updatedParams = [...prev];

                                                                    updatedParams[index].type = option?.value as IInputType | undefined;

                                                                    return updatedParams;
                                                                })}
                                                                name='type'
                                                                closeOnSelect
                                                                required
                                                                forceMobile
                                                                theme="legacy"
                                                                label="Campo"
                                                            />
                                                        </div>
                                                        <div className={classNames('field', { '--hidden': param.type !== 'select' })}>
                                                            <label htmlFor={`field_route_id_${index}`}>Rota para consultar o select</label>
                                                            <input id={`field_route_id_${index}`} name='route' onChange={(e) => handleChangeValue(index, e)} type="text" defaultValue={param.route} />
                                                        </div>
                                                        <div className="field">
                                                            <input id={`field_required_id_${index}`} onChange={(e) => handleChangeValue(index, e)} name='required' type="checkbox" defaultChecked={param.required} />
                                                            <label htmlFor={`field_required_id_${index}`}>Campo obrigatório</label>
                                                        </div>
                                                        <div className="field">
                                                            <input id={`field_label_id_${index}`} onChange={(e) => handleChangeValue(index, e)} name='show_label' type="checkbox" defaultChecked={param.show_label} />
                                                            <label htmlFor={`field_label_id_${index}`}>Apresentar placeholder como label</label>
                                                        </div>
                                                        <div className="actions">
                                                            <button type="button" onClick={() => handleRemoveParam(index)}>Remover</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="actions">
                                        <button type="button" onClick={handleAddParam}>Adicionar Parâmetro</button>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames('actions', { '--remove_active': removeReportConfirmation && auth.user?.role_id === ROLE_OPERATION })}>
                                <button type="button" className="cancel_button" onClick={() => setRemoveReportConfirmation(false)}>Cancelar</button>
                                <button type="button" className="confirm_button" onClick={handleRemoveReport}>Confirmar</button>
                                {
                                    (activeReportId && auth.user?.role_id === ROLE_OPERATION) &&
                                    <button
                                        type="button"
                                        className="remove_button"
                                        onClick={() => setRemoveReportConfirmation(true)}
                                        children='Remover'
                                    />
                                }
                                <button type="submit">Salvar</button>
                            </div>
                        </Form>
                    </main>
                </ModalContent>
            </Modal>
        </>
    );
}

export default UpdateReportModal;