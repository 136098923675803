import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import './style.scss';

export const Pagination = ({ pageCount, changePage, pageSelected }) => {
	function handlePageChange(event) {
		changePage(event.selected);

	}

	return (
		<>
			<ReactPaginate
				className="paginator"
				nextLabel={<ChevronRightIcon />}
				previousLabel={<ChevronLeftIcon />}
				pageRangeDisplayed={3}
				pageCount={pageCount || 1}
				onPageChange={handlePageChange}
				forcePage={pageSelected || 0}

			/>
		</>
	);
};