import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  Switch,
  WrapItem,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import {
  PrimaryResultsButton,
  CancelButton,
  TableButton,
} from "../../components/Buttons";
import TextInput from "../../components/TextInput";
import TextSelect from "../../components/TextSelect";

import { useUsers, useAuth, useHospitals } from "../../hooks";

import ProfileImage from "../../images/profile.png";
import addImage from "../../images/icon-wrapper.svg";

import TextSelectHospital from "../../components/TextSelectHospital";
import { estados } from "../../services/constants";
import { PERMISSION_REPORTS } from "../../types/permissions";
import { useQuery } from "@tanstack/react-query";
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";
import api from "../../services/api";
import Select from "../../components/Select";
import { ROLE_OPERATION } from "../../types/role";

const getReportList = async (uuid) => (await api.get('/report', { params: { uuid } })).data.reports;

const schema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email(),
  crm: yup.string(),
  uf: yup.string(),
  cpf: yup.string(),
  hospitals: yup.string().required("O campo hospitais é obrigatório"),
  cellphone: yup.string().required("O campo teleone móvel é obrigatório"),
  permiteContato: yup.boolean().required("O campo permite contato é obrigatório"),
  report: yup.boolean(),
  report_permissions: yup.array()
});

const EditProfile = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const listReportsRef = useRef(null);

  const activeHospital = useActiveHospital();
  const { user } = useAuth();
  const { getHospitalsList, getUserHospitalList } = useHospitals();

  const [photoBase64, setPhotoBase64] = useState();

  const [uuid, setUUID] = useState([]);
  const [codProcedencia, setCodProcedencia] = useState([]);

  const { getUser, editDoctor, editUser, editUserImage } = useUsers();
  const [userForm, setUserForm] = useState(null);

  const [profileImage, setProfileImage] = useState(null);
  const [hospitalsList, setHospitalsList] = useState(null);

  const [userReports, setUserReports] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { data: reportList } = useQuery({
    queryKey: ['reports', user.role_id === 1 ? { role_id: 1 } : { uuid: activeHospital.uuid }],
    queryFn: () => {
      listReportsRef.current?.abort();
      listReportsRef.current = new AbortController();

      return getReportList(activeHospital.uuid);
    },
    enabled: !!watch().report,
    retry: false,
    staleTime: 1 * 60 * 60 * 1000
  });

  async function getUserHospitalsList() {

    const response = await getUserHospitalList();

    const { hospitals } = response;

    return hospitals;
  }

  function getBase64() {
    let document = "";
    let reader = new FileReader();
    reader.readAsDataURL(profileImage);

    reader.onloadend = function (e) {
      document = reader.result;
      setPhotoBase64(document);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function getNumberCRM(string) {
    const newNumber = string.indexOf("/");
    return userForm?.crm.slice(0, newNumber);
  }

  function getState(string) {
    const newNumber = string.indexOf("/");
    return userForm?.crm.slice(newNumber + 1, newNumber + 3);
  }

  function getNames(objs, uuids) {
    return objs
      .filter((obj) => uuids.includes(obj.uuid))
      .map((obj) => obj.name);
  }

  function getProcedencia(objs, uuids) {
    return objs
      .filter((obj) => uuids.includes(obj.uuid))
      .map((obj) => obj.id ?? obj.id_hospital);
  }

  function listProc(value) {
    try {
      if (!value) {
        return;
      }

      if (uuid.includes(value)) {
        return;
      }

      setUUID(value);

      const codProc = getProcedencia(hospitalsList, [value]);
      setCodProcedencia(codProc);

    } catch (error) {
      console.log(error);
    }
  }

  const submit = async (values) => {
    const permissions = [];
    if (values.administrator) {
      permissions.push(1);
    }
    if (values.scheduling) {
      permissions.push(2);
    }
    if (values.results) {
      permissions.push(3);
    }
    if (values?.report) {
      permissions.push(PERMISSION_REPORTS);
    }

    const values_api = {
      phone: values?.phone,
      cellphone: values?.cellphone,
      hospitals: codProcedencia,
      permissions: permissions,
      report_permissions: values?.report_permissions ?? [],
      permiteContato: values?.permiteContato
    };


    const values_api_doctor = {
      phone: values?.phone,
      cellphone: values?.cellphone,
    };

    const formData = new FormData();
    formData.append("image", profileImage);
    formData.append("id_user", userId);


    if (userForm.role_id !== 4) {
      const result = await editUser(values_api, userId);
      if (profileImage) {
        await editUserImage(formData);
      }

      if (result?.status === 200) {
        navigate("/users");
      }
    }

    if (userForm.role_id === 4) {
      const result = await editDoctor(values_api_doctor, userId);
      if (profileImage) {
        await editUserImage(formData);
      }

      if (result.status === 200) {
        navigate("/users");
      }
    }
  };

  useEffect(() => {
    if (userId) {
      getUser(parseInt(userId)).then(async (data) => {
        const users_formatted = {};

        if (data.users.role_id === 2) {

          const permissions = data.users.hospitals[0].permissions.map((item) => item);

          if (permissions.includes(1)) {
            users_formatted.administrator = true;
          }

          if (permissions.includes(2)) {
            users_formatted.scheduling = true;
          }

          if (permissions.includes(3)) {
            users_formatted.results = true;
          }

          if (permissions.includes(PERMISSION_REPORTS)) {
            users_formatted.report = true;
          }
        }

        setValue("phone", data.users.phone || "");
        setValue("cellphone", data.users.cellphone || "");

        setUserForm({ ...data.users, ...users_formatted });

        const uuids = data.users.hospitals.map((item) => item.uuid);
        if (user.role_id === 1) {
          // const listHospital = await getHospitalList();

          const listHospital = await getHospitalsList();


          setUUID(uuids);
          setHospitalsList(listHospital);
          setCodProcedencia(getProcedencia(listHospital, uuids));

          return;
        }



        const listHospital = await getUserHospitalsList();
        setUUID(uuids);
        setHospitalsList(listHospital);
        setCodProcedencia(getProcedencia(listHospital, uuids));

      });
    }
  }, [userId]);

  useEffect(() => setValue('hospitals', uuid ? Array.isArray(uuid) ? uuid[0] : uuid : undefined), [uuid]);

  useEffect(() => {

    if (profileImage) {
      getBase64();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage]);

  useEffect(() => {
    setUserReports(userForm?.report_ids ?
      reportList?.filter(report =>
        userForm.role_id === ROLE_OPERATION ||
        userForm.report_ids.includes(report.id)
      ) : []
    );
  }, [userForm?.report_ids, reportList, user.role_id]);

  return (
    <>
      {userForm && (
        <Flex>
          <Box p="1rem" w='100%'>

            <Flex
              border="1px solid var(--cinza-card)"
              borderRadius="8px"
              p="46px 60px"
              backgroundColor="white"
              flexDirection="row"
            >
              <Flex
                width="200px"
                gap="10px"
                alignItems="start"
                flexDirection="column"
                borderRight="1px solid var(--orange-senne)"
                pr="60px"
                mr="30px"
              >
                <CardImage>
                  <img
                    src={photoBase64 ?? (userForm?.image || ProfileImage)}
                    alt=""
                  />
                </CardImage>

                <Box m="16px auto 0" w="160px">
                  {userForm.role_id === 2 && (
                    <Box>
                      <Flex
                        flexDirection="column"
                        gap="5px"
                        m="0 auto"
                        w="160px"
                        pb="24px"
                        borderBottom="1px solid var(--orange-senne)"
                      >
                        <Text color="var(--orange-senne)" as="strong">
                          {userForm?.name}
                        </Text>
                        <Text>Grupo</Text>
                        <Text as="strong">
                          {userForm?.hospitals[0]?.groupName}
                        </Text>
                      </Flex>
                      <Text as="strong">Editar Perfis</Text>
                      <Flex
                        flexDirection="column"
                        gap="10px"
                        mt="16px"
                        alignItems="start"
                      >
                        <Flex
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Switch
                            size="md"
                            colorScheme="orange"
                            name="scheduling"
                            id="scheduling"
                            defaultChecked={userForm?.scheduling}
                            // onChange={handleSwitchScheduling}
                            {...register("scheduling")}
                          />
                          <Text w="200px">Agendamento</Text>
                        </Flex>
                        <Flex
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Switch
                            size="md"
                            colorScheme="orange"
                            name="administrator"
                            id="administrator"
                            defaultChecked={userForm?.administrator}
                            {...register("administrator")}
                          />
                          <Text>Administrador</Text>
                        </Flex>
                        <Flex
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Switch
                            size="md"
                            colorScheme="orange"
                            name="results"
                            id="results"
                            defaultChecked={userForm?.results}
                            {...register("results")}
                          />
                          <Text>Resultados</Text>
                        </Flex>
                        {
                          userForm.role_id === 2 ?
                            (
                              <Flex
                                justifyContent="center"
                                alignContent="center"
                                alignItems="center"
                                gap="10px"
                              >
                                <Switch
                                  size="md"
                                  colorScheme="orange"
                                  name="permiteContato"
                                  id="permiteContato"
                                  defaultChecked={userForm?.permite_contato === 1}
                                  {...register("permiteContato")}
                                />
                                <Text>Ponto Focal para Resultados Críticos</Text>
                              </Flex>
                            ) :
                            null
                        }
                        <Flex
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                          gap="10px"
                        >
                          <Switch
                            size="md"
                            colorScheme="orange"
                            name="report"
                            id="report"
                            defaultChecked={!!userForm.report}
                            {...register("report")}
                          />
                          <Text>Relatórios</Text>
                        </Flex>
                        {!!watch().report &&
                          <ReportList>
                            <Controller
                              name="report_permissions"
                              control={control}
                              render={
                                ({ field }) =>
                                  <Select
                                    defaultValue={userReports.map(report => ({ value: report.id, label: report.label ?? report.name }))}
                                    multiple
                                    options={reportList.map(report => ({ value: report.id, label: report.label ?? report.name }))}
                                    onChange={(options) => field.onChange(options.map(option => option.value))}
                                  />
                              }
                            />
                          </ReportList>
                        }
                      </Flex>
                    </Box>
                  )}
                </Box>
              </Flex>
              <Flex width="calc(100% - 200px)" flexDirection="column" pl="32px">
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignContent="center"
                  pr="24px"
                >
                  <Text mb="24px" as="strong">
                    Informações Básicas
                  </Text>
                  <Box w="184px" maxH="30px">
                    <TableButton>
                      <img src={addImage} alt="" />
                      <Text ml="5px"> Adicionar nova foto</Text>
                      <input
                        type="file"
                        name="profileImage"
                        accept="image/png, image/jpeg"
                        onChange={(event) =>
                          setProfileImage(event.target.files[0])
                        }
                        style={{
                          position: "absolute",
                          width: "100%",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                      />
                    </TableButton>
                  </Box>
                </Flex>

                <form onSubmit={handleSubmit(submit)}>
                  <Box pr="24px">
                    <Flex justifyContent="space-around" gap="100px">
                      <TextInput
                        isError={errors.name}
                        register={{ ...register("name") }}
                        labelDescription="Nome Completo"
                        defaultValue={userForm?.name}
                        value={userForm?.name || null}
                        disabled={userForm?.name ? true : false}
                        InputPlaceholder={userForm?.name}
                        name="name"
                      />
                      {userForm.role_id !== 4 ? (
                        <TextInput
                          isError={errors.cpf}
                          register={{ ...register("cpf") }}
                          labelDescription="CPF"
                          name="cpf"
                          defaultValue={userForm?.cpf}
                          value={userForm?.cpf || null}
                          disabled={userForm?.cpf ? true : false}
                          maskInput="***.***.***.**"
                          InputPlaceholder={userForm?.cpf}
                        />
                      ) : (
                        <Flex alignItems="center" gap="10px" w="100%">
                          <TextInput
                            isError={errors.crm}
                            register={{ ...register("crm") }}
                            labelDescription="CRM"
                            name="CRM"
                            defaultValue={getNumberCRM(userForm?.crm)}
                            maskInput="******"
                            InputPlaceholder="1234567"
                          />
                          <TextSelect
                            isError={errors.uf}
                            register={{ ...register("uf") }}
                            labelDescription="UF"
                            value={getState(userForm.crm)}
                            name="uf"
                            id="uf"
                            titleOption="Escolha o UF"
                            options={estados}
                          />
                        </Flex>
                      )}
                    </Flex>

                    <Flex justifyContent="space-around" gap="100px">
                      <TextInput
                        isError={errors.cellphone}
                        register={{ ...register("cellphone") }}
                        labelDescription="Telefone Móvel"
                        InputPlaceholder="(99) 99999-9999"
                        defaultValue={userForm?.cellphone || null}
                        name="cellphone"
                        maskInput="(**) *****-****"
                        placeholder={userForm?.cellphone || null}
                      />

                      <TextInput
                        isError={errors.phone}
                        register={{ ...register("phone") }}
                        labelDescription="Telefone Fixo"
                        InputPlaceholder="(99) 99999-9999"
                        defaultValue={userForm?.phone || null}
                        name="phone"
                        maskInput="(**) *****-****"
                        placeholder={userForm?.phone || null}
                      ></TextInput>
                    </Flex>

                    <Flex justifyContent="space-around" gap="100px">
                      <TextInput
                        isError={errors.email}
                        register={{ ...register("email") }}
                        labelDescription="Email"
                        defaultValue={userForm?.email}
                        value={userForm?.email || null}
                        disabled={userForm?.email ? true : false}
                        InputPlaceholder={userForm?.email}
                        name="email"
                      ></TextInput>
                    </Flex>
                    <Flex flexDirection="column" mt="7px">
                      <Flex justifyContent="space-around" gap="100px">
                        <Flex w="100%" mt="0px" flexDirection="column">
                          <Controller
                            label='Hospital / Procedência'
                            name='hospitals'
                            control={control}
                            render={({ field }) =>
                              <Select
                                required
                                closeOnSelect
                                label='Hospital / Procedência'
                                value={uuid ? { value: Array.isArray(uuid) ? uuid[0] : uuid } : undefined}
                                options={hospitalsList ? hospitalsList.map(hospital => ({ value: hospital.uuid, label: hospital.name })) : []}
                                onChange={option => {
                                  if (option) {
                                    field.onChange(option.value ?? '');
                                    listProc(option.value ?? '');
                                  }
                                }}
                              />
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                  <Flex
                    justifyContent="end"
                    mt="100px"
                    alignContent="center"
                    alignItems="center"
                    gap="15px"
                  >
                    <Box w="86px" h="30px">
                      <CancelButton
                        onClick={() => navigate(`/users/${userId}`)}
                      >
                        Cancelar
                      </CancelButton>
                    </Box>

                    <Box w="68px" h="30px">
                      <PrimaryResultsButton type="submit">
                        Salvar
                      </PrimaryResultsButton>
                    </Box>
                  </Flex>
                </form>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

const ReportList = styled.div`
  width: 100%;

  & select {
    width: inherit;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    font-size: 14px;
    outline: none;

    transition: box-shadow .3s;

    &:hover {
      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .4);
    }

    &>option {
      cursor: pointer;
      transition: background-color .3s, color .2s;
      padding: 0 0 0 .3rem;

      &:hover {
        color: white;
        background-color: #F3953F;
      }

      &:checked {
        color: white;
        background-color: #D15D20;
      }
    }
  }
`;

const CardImage = styled.div`
  display: flex;
  margin: 0 auto 16px;
  padding: 8px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 24.7967px rgba(0, 0, 0, 0.1);
  img {
    width: 130px;
    height: 130px;
    border-radius: 60px;
    object-fit: cover;
  }
`;

const BlockInput = styled.div`
  label {
    display: block;
    font-size: 16px;
    line-height: 15px;
    color: #343a40;
    font-weight: bold;
    width: 100%;
  }
`;

export default EditProfile;
