import classNames from "classnames";
import LogoIcon from "../../assets/icons/LogoIcon";
import HouseIcon from "../../assets/icons/HouseIcon";
import CalendarSidebarIcon from "../../assets/icons/CalenarSidebarIcon";
import UserSidebarIcon from "../../assets/icons/UserSidebarIcon";
import FolderIcon from "../../assets/icons/FolderIcon";
import ExitIcon from "../../assets/icons/ExitIcon";
import MenuItem from "./MenuItem";
import UserIcon from "../../assets/icons/UserIcon";
import FolderPlusIcon from "../../assets/icons/FolderPlusIcon";
import api from "../../services/api";
import OccurrenceIcon from "../../assets/icons/OccurrenceIcon";
import PencilSquareIcon from "../../assets/icons/PencilSquareIcon";
import FlowSideBarIcon from "../../assets/icons/FlowSideBarIcon";
import AtendimentoSideBarIcon from "../../assets/icons/AtendimentoSideBarIcon";

import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AsideItem, IReport } from "./types";
import { useAuth } from "../../hooks";
import { useActiveHospital } from "../../contexts/ActiveHospital/useActiveHospital";
import { useQuery } from "@tanstack/react-query";
import { useReportModal } from "../../contexts/ReportModal/useReportModal";
import { PERMISSION_ADMINISTRATOR, PERMISSION_REPORTS, PERMISSION_SCHEDULING } from '../../types/permissions';
import { Role, ROLE_DOCTOR, ROLE_OPERATION, ROLE_PATIENT } from "../../types/role";
import { useHospitalList } from "../../hooks/useHospitalList";

import './style.scss';

const getAsideItems = (
    auth: IAuth,
    handleOpenReportModal: () => void,
    reports: AsideItem[],
    setChangeActiveHospitalIsOpen: Dispatch<SetStateAction<boolean>>,
    activeHospitalUuid?: string
): AsideItem[] => {
    const token = localStorage.getItem("Auth_senne");
    const userId = localStorage.getItem("User_id_senne");

    return [
        {
            content: 'Início',
            icon: <HouseIcon />,
            href: auth.user?.role_id === ROLE_PATIENT ? '/resultados-exames' : '/dashboard'
        },
        {
            content: 'Agendamento',
            href: `${process.env.REACT_APP_SCHEDULE_URL}/login/sso?token=${token}&hospitalUuid=${activeHospitalUuid}&userId=${userId}`,
            icon: <CalendarSidebarIcon />,
            permissions: [PERMISSION_SCHEDULING]
        },
        {
            content: 'Ocorrências',
            href: `/occurrence`,
            icon: <OccurrenceIcon />,
            role: ROLE_OPERATION
        },
        {
            content: 'Usuários',
            href: auth.user?.role_id === ROLE_OPERATION ? '/gerenciamento-senne' : '/users',
            icon: <UserSidebarIcon />,
            permissions: [PERMISSION_ADMINISTRATOR]
        },
        {
            content: 'Gestão Atendimento',
            href: 'http://192.168.0.252/app/atendimento/login-65e9b29fc97e99357e01b5df?environment=production',
            icon: <AtendimentoSideBarIcon />,
            role: ROLE_OPERATION
        },
        {
            content: 'NTO - Flow',
            href: 'http://192.168.0.252/app/flow/login-65e5fdd79b7a3317810eb242',
            icon: <FlowSideBarIcon />,
            role: ROLE_OPERATION
        },
        {
            content: 'Novo Relatório',
            href: undefined,
            icon: <FolderPlusIcon />,
            classname: '--from_awesome',
            onClick: () => handleOpenReportModal(),
            role: ROLE_OPERATION
        },
        {
            content: 'Relatório',
            href: undefined,
            icon: <FolderIcon />,
            classname: '--from_awesome',
            searchable: true,
            permissions: [PERMISSION_REPORTS],
            dropdown: reports
        },
        {
            content: 'Alterar Hospital',
            href: undefined,
            icon: <PencilSquareIcon />,
            classname: '--change_hospital',
            onClick: () => setChangeActiveHospitalIsOpen(true),
            permissions: [PERMISSION_REPORTS]
        },
        {
            classname: '--logout_bottom',
            content: 'Sair',
            href: '#exit',
            icon: <ExitIcon />,
            onClick: () => auth.logout()
        }
    ];
}

const formatReportToAside = (reports: IReport[]): AsideItem[] => {
    return reports.map(report => ({
        content: report.label ?? report.name,
        href: `/relatorio/${report.id}`
    }));
}

interface IAuth {
    user: {
        id: number;
        role_id: Role;
        hash: string;
        name: string;
        hospitals: {
            name: string;
        }[];
    };
    logout: () => void;
}

const Aside = ({ children }: { children: ReactNode }) => {
    const activeHospital = useActiveHospital();
    const reportModal = useReportModal();

    const auth = useAuth() as IAuth;
    const location = useLocation();

    const asideRef = useRef<HTMLElement>(null);
    const listReportsRef = useRef<AbortController | null>(null);
    const toggleAsideButtonRef = useRef<HTMLDivElement>(null);

    const [asideIsOpen, setAsideIsOpen] = useState(true);

    const { data: hospitals, isLoading } = useHospitalList(auth.user?.id);

    const { data: reports } = useQuery({
        queryKey: ['reports', auth.user?.role_id === 1 ? { role_id: 1 } : { uuid: activeHospital.uuid }], // Condição serve para não fazer requisições desnecessárias para role_id = 1, considerando que ele sempre terá todos relatórios disponíveis
        queryFn: async () => {
            listReportsRef.current?.abort();
            listReportsRef.current = new AbortController();

            return (await api.get<{ reports: IReport[] }>('/report', { params: { uuid: activeHospital.uuid } })).data.reports;
        },
        enabled: !!activeHospital.uuid || auth.user?.role_id === ROLE_OPERATION,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 6 * 60 * 60 * 1000
    });

    const menuItems = useMemo(
        () => {
            const menuItems = getAsideItems(
                auth,
                () => reportModal.setUpdateReportModalIsOpen(true),
                formatReportToAside(reports ?? []),
                activeHospital.setChangeActiveHospitalIsOpen,
                activeHospital.uuid
            ).filter(
                item => auth.user?.role_id === ROLE_OPERATION ||
                    (!item.permissions && !item.role) ||
                    item.permissions?.some(permission => activeHospital.permissions?.includes(permission)) ||
                    item.role === auth.user?.role_id
            );

            return <MenuItem asideItems={menuItems} pathname={location.pathname} />
        },
        [auth, activeHospital.permissions, activeHospital.uuid, location.pathname, reportModal, reports]
    );

    const footer = useMemo(() => <Footer />, []);

    useEffect(() => {
        const handleAsideClickOutside = (e: MouseEvent) => {
            if (
                window.innerWidth <= 768 &&
                asideRef.current &&
                toggleAsideButtonRef.current &&
                !asideRef.current.contains(e.target as Node) &&
                !toggleAsideButtonRef.current.contains(e.target as Node)
            ) {
                setAsideIsOpen(false);
            }
        };

        window.addEventListener('click', handleAsideClickOutside);

        return () => window.removeEventListener('click', handleAsideClickOutside);
    }, []);

    useEffect(() => {
        window.innerWidth <= 768 && setAsideIsOpen(false);
    }, [location.pathname]);

    return (
        <>
            <header className="header">
                <div className="header_logo">
                    <div className="icon">
                        <LogoIcon />
                    </div>
                    <div
                        ref={toggleAsideButtonRef}
                        className={classNames('header_aside_toggle', { '--aside_is_open': asideIsOpen })}
                        onClick={() => setAsideIsOpen(prev => !prev)}
                    />
                </div>
                {(
                    auth.user?.role_id !== ROLE_PATIENT &&
                    auth.user?.role_id !== ROLE_DOCTOR
                ) &&
                    <div
                        className={classNames('header_hospital_active', { '--no_edit': hospitals?.length && hospitals.length === 1 })}
                        onClick={() => activeHospital.setChangeActiveHospitalIsOpen(true)}
                    >
                        <span>{activeHospital.name || 'Nenhum hospital selecionado'}</span>
                    </div>
                }
                <div className="header_user">
                    <div className="header_user_content">
                        <div className="header_user_content_name">
                            {auth.user?.name}
                        </div>
                    </div>
                    <div className="header_user_image">
                        <UserIcon />
                    </div>
                </div>
            </header>
            <aside
                ref={asideRef}
                className={classNames('aside', { '--aside_is_open': asideIsOpen })}
                children={menuItems}
            />
            <div className={classNames('aside_content', { '--aside_is_open': asideIsOpen })} children={children} />
            <footer className="footer">
                {(
                    auth.user?.role_id !== ROLE_PATIENT &&
                    auth.user?.role_id !== ROLE_DOCTOR
                ) &&
                    <div
                        className={classNames('footer__hospital_active', { '--no_edit': hospitals?.length && hospitals.length === 1 })}
                        onClick={() => activeHospital.setChangeActiveHospitalIsOpen(true)}
                    >
                        <span>{activeHospital.name || 'Nenhum hospital selecionado'}</span>
                    </div>
                }
                {footer}
            </footer>
        </>
    );
}

export default Aside;

const Footer = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="footer_datetime">
                {new Intl.DateTimeFormat(
                    'pt-BR',
                    {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    }
                ).format(date)} - {new Intl.DateTimeFormat(
                    'pt-BR',
                    {
                        hour: '2-digit',
                        minute: '2-digit'
                    }
                ).format(date)}
            </div>
        </>
    );
}